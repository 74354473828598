<template>
  <div>
    <section class="image-feature ptb-120">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col-lg-5 col-12">
            <div class="feature-img-content">
              <div class="section-heading" data-aos="fade-up">
                <h2>Browse our world class features</h2>
                <p>
                  Energistically underwhelm progressive metrics via value-added
                  impact magnetic world-class paradigms portals. Pontificate
                  reliable metrics with enabled maintain clicks-and-mortar
                  manufactured.
                </p>
              </div>
              <ul
                class="list-unstyled d-flex flex-wrap list-two-col mb-0"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <li>
                  <div class="icon-box">
                    <i class="fas fa-users text-white bg-primary rounded"></i>
                  </div>
                  <h3 class="h5">Customer Aanalysis</h3>
                  <p>Objectively productize exceptional via customized.</p>
                </li>
                <li>
                  <div class="icon-box">
                    <i
                      class="fas fa-fingerprint text-white bg-danger rounded"
                    ></i>
                  </div>
                  <h3 class="h5">Real Time Metrics</h3>
                  <p>
                    Interactively integrate extensible users maximizing
                    resource.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="feature-img-holder p-5">
              <div
                class="p-5 bg-danger-soft position-relative rounded-custom d-block feature-img-wrap"
              >
                <div class="position-relative">
                  <img
                    src="@/assets/img/screen/widget-5.png"
                    class="img-fluid rounded-custom position-relative"
                    alt="feature-image"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  />
                  <img
                    src="@/assets/img/screen/widget-3.png"
                    class="img-fluid rounded-custom shadow position-absolute bottom--100 right--100 hide-medium"
                    alt="feature-image"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  />
                </div>
                <div
                  class="position-absolute bg-dark-soft z--1 dot-mask dm-size-12 dm-wh-250 top-left"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="image-feature ptb-60">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col-lg-5 col-12 order-lg-1">
            <div class="feature-img-content">
              <div class="section-heading" data-aos="fade-up">
                <h2>Measure your efficiency for business</h2>
                <p>
                  Reliable metrics with enabled infomediaries. Holisticly
                  maintain clicks-and-mortar manufactured products empower viral
                  customer service through resource supply pandemic
                  collaboration.
                </p>
              </div>
              <ul
                class="list-unstyled d-flex flex-wrap list-two-col mb-0"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <li>
                  <div class="icon-box">
                    <i
                      class="fas fa-dollar-sign text-white bg-success rounded"
                    ></i>
                  </div>
                  <h3 class="h5">Finance Models</h3>
                  <p>
                    Objectively error-free develop intuitive catalysts for
                    change.
                  </p>
                </li>
                <li>
                  <div class="icon-box">
                    <i class="fas fa-headset text-white bg-dark rounded"></i>
                  </div>
                  <h3 class="h5">Daily Consultancy</h3>
                  <p>
                    Compellingly initiate distinctive principle-centered
                    sources.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6 col-12 order-lg-0">
            <div class="feature-img-holder p-5">
              <div
                class="bg-primary-soft p-5 rounded-custom position-relative d-block feature-img-wrap"
              >
                <div class="position-relative">
                  <img
                    src="@/assets/img/screen/widget-8.png"
                    class="img-fluid rounded-custom position-relative"
                    alt="feature-image"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  />
                  <img
                    src="@/assets/img/screen/widget-6.png"
                    class="img-fluid rounded-custom shadow position-absolute top--100 left--100 hide-medium"
                    alt="feature-image"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  />
                </div>
                <div
                  class="position-absolute bg-dark-soft z--1 dot-mask dm-size-12 dm-wh-250 bottom-right"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="image-feature ptb-120">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col-lg-5 col-12">
            <div class="feature-img-content">
              <div class="section-heading" data-aos="fade-up">
                <h2>We create software & digital product</h2>
                <p>
                  Credibly deliver out-of-the-box benefits through just in time
                  best practices. Globally orchestrate holistic communities
                  without visualize performance based premium alignments.
                </p>
              </div>
              <ul
                class="list-unstyled d-flex flex-wrap list-two-col mt-5"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <li>
                  <div class="icon-box">
                    <i
                      class="fas fa-briefcase text-white bg-warning rounded"
                    ></i>
                  </div>
                  <h3 class="h5">Fast Services</h3>
                  <p>
                    Rapidiously exploit robust solutions without alignments.
                  </p>
                </li>
                <li>
                  <div class="icon-box">
                    <i class="fas fa-truck text-white bg-info rounded"></i>
                  </div>
                  <h3 class="h5">Free Shipping</h3>
                  <p>
                    Interactively integrate whereas extensible users resource.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="feature-img-holder p-5">
              <div
                class="bg-danger-soft p-5 rounded-custom position-relative d-block feature-img-wrap"
              >
                <div class="position-relative">
                  <img
                    src="@/assets/img/screen/widget-9.png"
                    class="img-fluid rounded-custom position-relative"
                    alt="feature-image"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  />
                  <img
                    src="@/assets/img/screen/widget-10.png"
                    class="img-fluid rounded-custom shadow position-absolute top--100 right--100 hide-medium"
                    alt="feature-image"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  />
                </div>
                <div
                  class="position-absolute bg-dark-soft z--1 dot-mask dm-size-12 dm-wh-250 bottom-left"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ImgContentThree",
};
</script>
