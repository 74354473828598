var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"cyber-price ptb-120 bg-light"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row g-0"},[_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"cyber-price-chart"},[_c('div',{staticClass:"position-relative single-pricing-wrap rounded bg-white custom-shadow p-5 mb-4 mb-lg-0"},[_vm._m(1),_vm._m(2),_c('router-link',{staticClass:"btn btn-outline-primary mt-2",attrs:{"to":"/request-for-demo"}},[_vm._v("Get Started Now")])],1)])]),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"cyber-price-chart"},[_c('div',{staticClass:"position-relative single-pricing-wrap rounded bg-gradient text-white p-5 mb-4 mb-lg-0"},[_vm._m(3),_vm._m(4),_c('router-link',{staticClass:"btn btn-primary mt-2",attrs:{"to":"/request-for-demo"}},[_vm._v("Get Started Now")])],1)])]),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"cyber-price-chart"},[_c('div',{staticClass:"position-relative single-pricing-wrap rounded bg-white custom-shadow p-5 mb-4 mb-lg-0"},[_vm._m(5),_vm._m(6),_c('router-link',{staticClass:"btn btn-outline-primary mt-2",attrs:{"to":"/request-for-demo"}},[_vm._v("Get Started Now")])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-8 col-lg-6"},[_c('div',{staticClass:"section-heading text-center mb-5"},[_c('h5',{staticClass:"h6 text-primary"},[_vm._v("Pricing Table")]),_c('h2',[_vm._v("Affordable Price Plans")]),_c('p',[_vm._v(" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricing-header mb-32"},[_c('h4',{staticClass:"display-6 fw-semi-bold"},[_vm._v("$29/M")]),_c('span',[_vm._v("Free Plan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricing-info mb-4"},[_c('ul',{staticClass:"pricing-feature-list list-unstyled"},[_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v("1 Team")]),_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v(" 1 Installed Agent ")]),_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v(" Real-Time Feedback ")]),_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v(" Video Dedicated Support ")]),_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v(" 1 Attacked Targets Per Month ")]),_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v(" Team Collaboration Tools ")]),_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v(" 1 Attacked Targets Per Month ")]),_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v(" Automated Updated Features ")]),_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v(" 24/7 Life time Support ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricing-header mb-32"},[_c('h4',{staticClass:"display-6 fw-semi-bold"},[_vm._v("$49/M")]),_c('span',{staticClass:"text-white"},[_vm._v("Free Plan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricing-info mb-4"},[_c('ul',{staticClass:"pricing-feature-list list-unstyled"},[_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v("1 Team")]),_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v(" 1 Installed Agent ")]),_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v(" Real-Time Feedback ")]),_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v(" Video Dedicated Support ")]),_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v(" 1 Attacked Targets Per Month ")]),_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v(" Team Collaboration Tools ")]),_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v(" 1 Attacked Targets Per Month ")]),_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v(" Automated Updated Features ")]),_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v(" 24/7 Life time Support ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricing-header mb-32"},[_c('h4',{staticClass:"display-6 fw-semi-bold"},[_vm._v("$29/M")]),_c('span',[_vm._v("Free Plan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricing-info mb-4"},[_c('ul',{staticClass:"pricing-feature-list list-unstyled"},[_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v("1 Team")]),_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v(" 1 Installed Agent ")]),_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v(" Real-Time Feedback ")]),_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v(" Video Dedicated Support ")]),_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v(" 1 Attacked Targets Per Month ")]),_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v(" Team Collaboration Tools ")]),_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v(" 1 Attacked Targets Per Month ")]),_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v(" Automated Updated Features ")]),_c('li',[_c('i',{staticClass:"far fa-check text-success me-2"}),_vm._v(" 24/7 Life time Support ")])])])
}]

export { render, staticRenderFns }