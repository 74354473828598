<template>
  <section class="game-price ptb-120">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="section-title text-center mb-5">
            <h2 class="text-white mb-4">Check Out Our Hosting Suite</h2>
            <p class="m-0 text-off-white">
              There are many variations of passages of Lorem Ipsum available,
              but the majority randomised words which don't look even slightly
              believable.
            </p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="single-game-price mb-4 mb-lg-0">
            <div class="d-flex align-items-center">
              <div class="price-icon me-3 flex-shrink-0">
                <img src="@/assets/img/wordpress.png" alt="icon" />
              </div>
              <div>
                <h5 class="text-white mb-1">WordPress Hosting</h5>
                <span class="text-off-white">Starting at $2.99/mo </span>
              </div>
            </div>
            <p class="py-3 m-0">
              Whether hosting community forums, an online store, or even a blog,
              this platform is built to meet and surpass your every regard.
            </p>
            <ul class="list-unstyled">
              <li class="pb-2">
                <i class="far fa-check me-2"></i>
                <span>50+ Pro Widgets</span>
              </li>
              <li class="pb-2">
                <i class="far fa-check me-2"></i>
                <span>300+ Pro Templates</span>
              </li>
              <li class="pb-2">
                <i class="far fa-check me-2"></i>
                <span>Theme Builder</span>
              </li>
              <li class="pb-2">
                <i class="far fa-check me-2"></i>
                <span>Woo Commers Builder</span>
              </li>
              <li class="pb-2">
                <i class="far fa-check me-2"></i>
                <span>Support for 1 Year</span>
              </li>
              <li class="pb-2">
                <i class="far fa-check me-2"></i>
                <span>Updates for 1 Years</span>
              </li>
              <li class="pb-2">
                <i class="far fa-check me-2"></i>
                <span>WordPress Staging Tool</span>
              </li>
              <li class="pb-2">
                <i class="far fa-check me-2"></i>
                <span>Multiple Data Centers</span>
              </li>
            </ul>
            <div class="game-price-btn">
              <router-link to="/contact" class="mt-4">
                Check More Plans
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="single-game-price mb-4 mb-lg-0 active">
            <div class="d-flex align-items-center">
              <div class="price-icon me-3 flex-shrink-0">
                <img src="@/assets/img/cloud.png" alt="icon" />
              </div>
              <div>
                <h5 class="text-white mb-1">Shared Hosting</h5>
                <span class="text-off-white">Starting at $2.99/mo </span>
              </div>
            </div>
            <p class="py-3 m-0">
              Whether hosting community forums, an online store, or even a blog,
              this platform is built to meet and surpass your every regard.
            </p>
            <ul class="list-unstyled">
              <li class="pb-2">
                <i class="far fa-check me-2"></i>
                <span>50+ Pro Widgets</span>
              </li>
              <li class="pb-2">
                <i class="far fa-check me-2"></i>
                <span>300+ Pro Templates</span>
              </li>
              <li class="pb-2">
                <i class="far fa-check me-2"></i>
                <span>Theme Builder</span>
              </li>
              <li class="pb-2">
                <i class="far fa-check me-2"></i>
                <span>Woo Commers Builder</span>
              </li>
              <li class="pb-2">
                <i class="far fa-check me-2"></i>
                <span>Support for 1 Year</span>
              </li>
              <li class="pb-2">
                <i class="far fa-check me-2"></i>
                <span>Updates for 1 Years</span>
              </li>
              <li class="pb-2">
                <i class="far fa-check me-2"></i>
                <span>WordPress Staging Tool</span>
              </li>
              <li class="pb-2">
                <i class="far fa-check me-2"></i>
                <span>Multiple Data Centers</span>
              </li>
            </ul>
            <div class="game-price-btn">
              <router-link to="/contact" class="mt-4">
                Check More Plans
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="single-game-price">
            <div class="d-flex align-items-center">
              <div class="price-icon me-3 flex-shrink-0">
                <img src="@/assets/img/globe.png" alt="icon" />
              </div>
              <div>
                <h5 class="text-white mb-1">VPS Hosting</h5>
                <span class="text-off-white">Starting at $2.99/mo </span>
              </div>
            </div>
            <p class="py-3 m-0">
              Whether hosting community forums, an online store, or even a blog,
              this platform is built to meet and surpass your every regard.
            </p>
            <ul class="list-unstyled">
              <li class="pb-2">
                <i class="far fa-check me-2"></i>
                <span>50+ Pro Widgets</span>
              </li>
              <li class="pb-2">
                <i class="far fa-check me-2"></i>
                <span>300+ Pro Templates</span>
              </li>
              <li class="pb-2">
                <i class="far fa-check me-2"></i>
                <span>Theme Builder</span>
              </li>
              <li class="pb-2">
                <i class="far fa-check me-2"></i>
                <span>Woo Commers Builder</span>
              </li>
              <li class="pb-2">
                <i class="far fa-check me-2"></i>
                <span>Support for 1 Year</span>
              </li>
              <li class="pb-2">
                <i class="far fa-check me-2"></i>
                <span>Updates for 1 Years</span>
              </li>
              <li class="pb-2">
                <i class="far fa-check me-2"></i>
                <span>WordPress Staging Tool</span>
              </li>
              <li class="pb-2">
                <i class="far fa-check me-2"></i>
                <span>Multiple Data Centers</span>
              </li>
            </ul>
            <div class="game-price-btn">
              <router-link to="/contact" class="mt-4">
                Check More Plans
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
