<template>
  <section class="feature-section pt-60 pb-120">
    <div class="container">
      <div class="row align-items-lg-center justify-content-between">
        <div class="col-lg-5 order-lg-2 mb-7 mb-lg-0">
          <div class="mb-4" data-aos="fade-up">
            <h2>Experience your Product with Integration</h2>
            <p>
              Conveniently drive stand-alone total linkage for process-centric
              content enthusiastically administrate robust collaborative.
            </p>
          </div>
          <ul
            class="list-unstyled d-flex flex-wrap list-two-col mt-5"
            data-aos="fade-up"
            data-aos-delay="50"
          >
            <li>
              <span class="d-block mb-4"
                ><i class="fal fa-user fa-2x text-primary"></i
              ></span>
              <h3 class="h5">Customer analysis</h3>
              <p>Objectively exceptional via customized capital expertise.</p>
            </li>
            <li>
              <span class="d-block mb-4"
                ><i class="fal fa-watch fa-2x text-primary"></i
              ></span>
              <h3 class="h5">Real time metrics</h3>
              <p>Interactively integrate extensible users resource.</p>
            </li>
          </ul>
        </div>
        <div class="col-lg-6 order-lg-1">
          <div class="pr-lg-4 position-realtive">
            <div
              class="bg-light text-center rounded-custom overflow-hidden p-5 mx-lg-auto"
              data-aos="fade-up"
              data-aos-delay="50"
            >
              <img
                src="@/assets/img/dashboard-img.png"
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeatureSix',
};
</script>
