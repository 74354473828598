<template>
  <section
    class="page-header position-relative overflow-hidden ptb-120 bg-dark"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-md-12">
          <div class="section-heading text-center">
            <h1 class="display-5 fw-bold">Our Latest News and Blogs</h1>
            <p class="lead mb-0">
              Completely integrate equity invested partnerships without
              revolutionary systems. Monotonectally network pandemic e-services
              via bricks-and-clicks information.
            </p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center text-center">
        <div class="col-xl-8">
          <a
            href="javascript:;"
            class="btn btn-soft-primary btn-pill btn-sm m-2"
            >Marketing</a
          >
          <a
            href="javascript:;"
            class="btn btn-soft-primary btn-pill btn-sm m-2"
            >Sales</a
          >
          <a
            href="javascript:;"
            class="btn btn-soft-primary btn-pill btn-sm m-2"
            >Design</a
          >
          <a
            href="javascript:;"
            class="btn btn-soft-primary btn-pill btn-sm m-2"
            >Development</a
          >
          <a
            href="javascript:;"
            class="btn btn-soft-primary btn-pill btn-sm m-2"
            >Product Design</a
          >
          <a
            href="javascript:;"
            class="btn btn-soft-primary btn-pill btn-sm m-2"
            >Customers</a
          >
          <a
            href="javascript:;"
            class="btn btn-soft-primary btn-pill btn-sm m-2"
            >Agency</a
          >
          <a
            href="javascript:;"
            class="btn btn-soft-primary btn-pill btn-sm m-2"
            >Investors</a
          >
          <a
            href="javascript:;"
            class="btn btn-soft-primary btn-pill btn-sm m-2"
            >Research</a
          >
        </div>
      </div>
      <div
        class="bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light right-5"
      ></div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
