<template>
  <section class="payment-feature-img pt-200">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5">
          <div class="payment-feature-img-left mb-5 mb-lg-0">
            <h2 class="mb-4">
              Investing isn’t easy We just Professional Way it feel that way
            </h2>
            <p class="mb-4">
              Globally expedite sticky platforms whereas end-to-end vortals.
              Energistically synergize emerging . Monotonectally incubate
              bleeding-edge e-business Appropriately matrix quality core
              competencies functionalities.ompetently pursue client-centric
              relationships .
            </p>
            <router-link to="/request-for-demo" class="btn-gradient-sqr"
              >Get Started</router-link
            >
          </div>
        </div>
        <div class="col-lg-7">
          <div
            class="payment-feature-img-right text-center position-relative mt-5 mt-lg-0"
          >
            <div class="payment-feature-mockup position-relative">
              <img
                src="@/assets/img/payment-mock.png"
                class="img-fluid"
                alt="Mocup"
              />
            </div>
            <div class="payment-feture-shape">
              <img
                src="@/assets/img/payment-ft-2.png"
                class="shape-one shadow-lg"
                alt="Tax"
              />
              <img
                src="@/assets/img/payment-ft.png"
                class="shape-two shadow-lg"
                alt="Tax"
              />
              <img src="@/assets/img/p-dot.png" class="shape-three" alt="dot" />
              <img
                src="@/assets/img/blue-shape.png"
                class="shape-four"
                alt="shape"
              />
              <img
                src="@/assets/img/pink-circle.png"
                class="shape-five"
                alt="shape"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
