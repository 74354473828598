<template>
  <section class="faq-section pt-60 pb-120 bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-6">
          <div class="section-heading text-center">
            <h4 class="h5 text-primary">FAQ</h4>
            <h2>Frequently Asked Questions</h2>
            <p>
              Conveniently mesh cooperative services via magnetic outsourcing.
              Dynamically grow value whereas accurate e-commerce vectors.
            </p>
          </div>
        </div>
      </div>
      <div class="row align-items-center justify-content-between">
        <div class="col-lg-5 col-12">
          <div class="faq-wrapper">
            <div class="faq-item mb-5">
              <h5>
                <span class="h3 text-primary me-2">1.</span> How does back
                pricing work?
              </h5>
              <p>
                Progressively e-enable collaborative inexpensive supply chains.
                Efficiently maintain economically methods of empowerment for
                synergistic sound scenarios.
              </p>
            </div>
            <div class="faq-item mb-5">
              <h5>
                <span class="h3 text-primary me-2">2.</span> How do I calculate
                how much price?
              </h5>
              <p>
                Globally benchmark customized mindshare before clicks-and-mortar
                partnerships. Efficiently maintain economically sound scenarios
                and whereas client-based progressively.
              </p>
            </div>
            <div class="faq-item">
              <h5>
                <span class="h3 text-primary me-2">3.</span> Can you show me an
                example?
              </h5>
              <p>
                Dynamically visualize whereas competitive relationships.
                Progressively benchmark customized partnerships generate
                interdependent benefits rather sound scenarios and robust
                alignments.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="text-center mt-4 mt-lg-0 mt-md-0">
            <img src="@/assets/img/faq.svg" alt="faq" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FaqOne',
};
</script>

<style></style>
