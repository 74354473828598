<template>
  <section
    class="page-header position-relative overflow-hidden ptb-120 bg-dark"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-md-12">
          <div class="text-center">
            <h1 class="display-5 fw-bold">Support</h1>
            <p class="lead">
              Seamlessly actualize client-based users after out-of-the-box
              value. Globally embrace strategic high-quality platforms before
              frictionless expertise.
            </p>

            <div class="form-block-banner mw-60 m-auto mt-5">
              <form name="search" class="search-form d-flex">
                <input
                  type="email"
                  class="form-control me-2"
                  name="search"
                  data-name="search"
                  placeholder="Search for a topic or question"
                  id="searchForm"
                  required=""
                />
                <input
                  type="submit"
                  value="Search"
                  data-wait="Please wait..."
                  class="btn btn-primary"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        class="bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light right-5"
      ></div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
