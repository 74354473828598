<template>
  <section class="work-process ptb-120">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-6">
          <div class="section-heading text-center">
            <h4 class="h5 text-primary">Process</h4>
            <h2>We Follow Our Work Process</h2>
            <p>
              Conveniently mesh cooperative services via magnetic outsourcing.
              Dynamically grow functionalized value whereas accurate e-commerce.
            </p>
          </div>
        </div>
      </div>
      <div class="row d-flex align-items-center">
        <div class="col-md-6 col-lg-3">
          <div
            class="process-card text-center px-4 py-5 rounded-custom shadow-hover mb-4 mb-lg-0"
          >
            <div
              class="process-icon border border-light bg-custom-light rounded-custom p-3"
            >
              <span class="h2 mb-0 text-primary fw-bold">1</span>
            </div>
            <h3 class="h5">Research</h3>
            <p class="mb-0">Uniquely pursue compelling initiatives expanded.</p>
          </div>
        </div>
        <div class="dots-line first"></div>
        <div class="col-md-6 col-lg-3">
          <div
            class="process-card text-center px-4 py-5 rounded-custom shadow-hover mb-4 mb-lg-0"
          >
            <div
              class="process-icon border border-light bg-custom-light rounded-custom p-3"
            >
              <span class="h2 mb-0 text-primary fw-bold">2</span>
            </div>
            <h3 class="h5">Designing</h3>
            <p class="mb-0">Uniquely pursue compelling initiatives expanded.</p>
          </div>
        </div>
        <div class="dots-line first"></div>
        <div class="col-md-6 col-lg-3">
          <div
            class="process-card text-center px-4 py-5 rounded-custom shadow-hover mb-4 mb-lg-0 mb-md-0"
          >
            <div
              class="process-icon border border-light bg-custom-light rounded-custom p-3"
            >
              <span class="h2 mb-0 text-primary fw-bold">3</span>
            </div>
            <h3 class="h5">Building</h3>
            <p class="mb-0">Uniquely pursue compelling initiatives expanded.</p>
          </div>
        </div>
        <div class="dots-line first"></div>
        <div class="col-md-6 col-lg-3">
          <div
            class="process-card text-center px-4 py-5 rounded-custom shadow-hover mb-0 mb-lg-0 mb-md-0"
          >
            <div
              class="process-icon border border-light bg-custom-light rounded-custom p-3"
            >
              <span class="h2 mb-0 text-primary fw-bold">4</span>
            </div>
            <h3 class="h5">Deliver</h3>
            <p class="mb-0">Uniquely pursue compelling initiatives expanded.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WorkProcessFour',
};
</script>
