<template>
  <div class="customer-section pb-120 bg-dark">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-12">
          <ul class="customer-logos-grid text-center list-unstyled mb-0">
            <li>
              <img
                src="@/assets/img/clients/client-logo-1.svg"
                width="150"
                alt="clients logo"
                class="img-fluid customer-logo p-1 p-md-2 p-lg-3 m-auto"
                data-aos="fade-up"
                data-aos-delay="50"
              />
            </li>
            <li>
              <img
                src="@/assets/img/clients/client-logo-2.svg"
                width="150"
                alt="clients logo"
                class="img-fluid customer-logo p-1 p-md-2 p-lg-3 m-auto"
                data-aos="fade-up"
                data-aos-delay="50"
              />
            </li>
            <li>
              <img
                src="@/assets/img/clients/client-logo-3.svg"
                width="150"
                alt="clients logo"
                class="img-fluid customer-logo p-1 p-md-2 p-lg-3 m-auto"
                data-aos="fade-up"
                data-aos-delay="50"
              />
            </li>
            <li>
              <img
                src="@/assets/img/clients/client-logo-4.svg"
                width="150"
                alt="clients logo"
                class="img-fluid customer-logo p-1 p-md-2 p-lg-3 m-auto"
                data-aos="fade-up"
                data-aos-delay="50"
              />
            </li>
            <li>
              <img
                src="@/assets/img/clients/client-logo-5.svg"
                width="150"
                alt="clients logo"
                class="img-fluid customer-logo p-1 p-md-2 p-lg-3 m-auto"
                data-aos="fade-up"
                data-aos-delay="100"
              />
            </li>
            <li>
              <img
                src="@/assets/img/clients/client-logo-6.svg"
                width="150"
                alt="clients logo"
                class="img-fluid customer-logo p-1 p-md-2 p-lg-3 m-auto"
                data-aos="fade-up"
                data-aos-delay="100"
              />
            </li>
            <li>
              <img
                src="@/assets/img/clients/client-logo-7.svg"
                width="150"
                alt="clients logo"
                class="img-fluid customer-logo p-1 p-md-2 p-lg-3 m-auto"
                data-aos="fade-up"
                data-aos-delay="100"
              />
            </li>
            <li>
              <img
                src="@/assets/img/clients/client-logo-8.svg"
                width="150"
                alt="clients logo"
                class="img-fluid customer-logo p-1 p-md-2 p-lg-3 m-auto"
                data-aos="fade-up"
                data-aos-delay="100"
              />
            </li>
          </ul>
          <p
            class="text-center mt-5 mb-0"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            Trusted More than 25,00+ Companies Around the World
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BrandDark',
};
</script>
