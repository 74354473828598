<template>
  <section class="feature-section">
    <div class="container-fluid">
      <div
        class="feature-container mx-lg-5 position-relative bg-gradient ptb-120 rounded-custom"
      >
        <img
          src="@/assets/img/feature-bg-1.jpg"
          alt="feature-bg"
          class="feature-bg-mockup position-absolute w-100 h-100 left-0 right-0 top-0 bottom-0 rounded-custom"
        />
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="feature-bg-img-content position-relative z-5">
                <div class="section-heading">
                  <h2>Build Interactions Visually Website for Your Company</h2>
                  <p>
                    Intrinsicly impact resource sucking initiatives with 24/7
                    relationships communicate integrated products via
                    synergistic leadership skills. Conveniently pursue
                    future-proof web services through empowered bandwidth.
                  </p>
                </div>

                <ul
                  class="list-unstyled d-flex flex-wrap list-two-col mt-5 mb-0"
                >
                  <li class="py-3">
                    <h3 class="feature-number text-primary mb-2">55%</h3>
                    <p>Compellingly engage corporate scenarios for monetize.</p>
                  </li>
                  <li class="py-3">
                    <h3 class="feature-number text-primary mb-2">5K+</h3>
                    <p>
                      Conveniently target enterprise vortals for
                      process-centric.
                    </p>
                  </li>
                  <li class="py-3">
                    <h3 class="feature-number text-primary mb-2">15M+</h3>
                    <p>
                      Completely strategize long-term high-impact paradigms.
                    </p>
                  </li>
                  <li class="py-3">
                    <h3 class="feature-number text-primary mb-2">95%</h3>
                    <p>
                      Uniquely negotiate compelling platforms via standardized.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
