<template>
  <section class="promo-section ptb-120 bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-6">
          <div class="section-heading text-center" data-aos="fade-up">
            <h2>Our Customers Get Results</h2>
            <p>
              Progressively deploy market positioning catalysts for change and
              technically sound. Authoritatively with backward-compatible
              e-services.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-lg-4 mt-4 mt-lg-0 mt-md-0">
          <div
            class="bg-dark p-5 text-center d-flex flex-column h-100 rounded-custom"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div class="promo-card-info mb-4">
              <h3 class="display-5 fw-bold mb-4">
                <i class="far fa-fingerprint text-warning me-2"></i> 10x
              </h3>
              <p>
                Embrace distinctive best practices after B2B syndicate backend
                internal or whereas edge process improvements.
              </p>
            </div>
            <div class="mt-auto">
              <img
                src="@/assets/img/clients/client-logo-4.svg"
                width="120"
                alt="clients"
                class="img-fluid me-auto customer-logo"
              />
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 mt-4 mt-lg-0 mt-md-0">
          <div
            class="bg-dark p-5 text-center d-flex flex-column h-100 rounded-custom"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            <div class="promo-card-info mb-4">
              <h3 class="display-5 fw-bold mb-4">
                <i class="far fa-paper-plane text-warning me-2"></i> 5k
              </h3>
              <p>
                Rapidiously embrace distinctive best practices B2B syndicate
                backend internal or whereas process improvements.
              </p>
            </div>
            <div class="mt-auto">
              <img
                src="@/assets/img/clients/client-logo-2.svg"
                width="120"
                alt="clients"
                class="img-fluid me-auto customer-logo"
              />
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 mt-4 mt-lg-0">
          <div
            class="bg-dark p-5 text-center d-flex flex-column h-100 rounded-custom"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div class="promo-card-info mb-4">
              <h3 class="display-5 fw-bold mb-4">
                <i class="far fa-chart-pie-alt text-warning me-2"></i> 95%
              </h3>
              <p>
                Distinctive best practices after B2B syndicate internal or
                whereas bleeding-edge process improvements.
              </p>
            </div>
            <div class="mt-auto">
              <img
                src="@/assets/img/clients/client-logo-3.svg"
                width="120"
                alt="clients"
                class="img-fluid me-auto customer-logo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PromoTwo',
};
</script>

<style></style>
