var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pricing-section ptb-120 position-relative z-2"},[_c('div',{staticClass:"container"},[_c('div',{class:_vm.hasTitle ? 'row justify-content-center' : 'd-none'},[_vm._m(0)]),_c('div',{staticClass:"mb-5",class:_vm.hasTitle ? 'row justify-content-center' : 'd-none'},[_vm._m(1),_vm._m(2),_vm._m(3)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"position-relative single-pricing-wrap rounded-custom bg-white custom-shadow p-5 mb-4 mb-lg-0"},[_vm._m(4),_vm._m(5),_c('router-link',{staticClass:"btn btn-outline-primary mt-2",attrs:{"router-link":"","to":"/request-for-demo"}},[_vm._v("Buy Now")]),_vm._m(6)],1)]),_c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"position-relative single-pricing-wrap rounded-custom bg-gradient text-white p-5 mb-4 mb-lg-0"},[_vm._m(7),_vm._m(8),_c('router-link',{staticClass:"btn btn-primary mt-2",attrs:{"to":"/request-for-demo"}},[_vm._v("Buy Now")])],1)]),_c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"position-relative single-pricing-wrap rounded-custom bg-white custom-shadow p-5 mb-4 mb-lg-0"},[_vm._m(9),_vm._m(10),_c('router-link',{staticClass:"btn btn-outline-primary mt-2",attrs:{"to":"/request-for-demo"}},[_vm._v("Buy Now")]),_vm._m(11)],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-6 col-md-10"},[_c('div',{staticClass:"section-heading text-center"},[_c('h4',{staticClass:"h5 text-primary"},[_vm._v("Pricing")]),_c('h2',[_vm._v("Check Our Valuable Price")]),_c('p',[_vm._v(" Conveniently mesh cooperative services via magnetic outsourcing. Dynamically grow value whereas accurate e-commerce vectors. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"media d-flex align-items-center py-2 p-sm-2"},[_c('div',{staticClass:"icon-box mb-0 bg-primary-soft rounded-circle d-block me-3"},[_c('i',{staticClass:"fal fa-credit-card text-primary"})]),_c('div',{staticClass:"media-body fw-medium h6 mb-0"},[_vm._v(" No credit card required ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"media d-flex align-items-center py-2 p-sm-2"},[_c('div',{staticClass:"icon-box mb-0 bg-success-soft rounded-circle d-block me-3"},[_c('i',{staticClass:"fal fa-calendar-check text-success"})]),_c('div',{staticClass:"media-body fw-medium h6 mb-0"},[_vm._v(" Get 30 day free trial ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"media d-flex align-items-center py-2 p-sm-2"},[_c('div',{staticClass:"icon-box mb-0 bg-danger-soft rounded-circle d-block me-3"},[_c('i',{staticClass:"fal fa-calendar-times text-danger"})]),_c('div',{staticClass:"media-body fw-medium h6 mb-0"},[_vm._v("Cancel anytime")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricing-header mb-32"},[_c('h3',{staticClass:"package-name text-primary d-block"},[_vm._v("Stater")]),_c('h4',{staticClass:"display-6 fw-semi-bold"},[_vm._v("$25"),_c('span',[_vm._v("/month")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricing-info mb-4"},[_c('ul',{staticClass:"pricing-feature-list list-unstyled"},[_c('li',[_c('i',{staticClass:"fas fa-circle fa-2xs text-primary me-2"}),_vm._v(" 1 Team ")]),_c('li',[_c('i',{staticClass:"fas fa-circle fa-2xs text-primary me-2"}),_vm._v(" 1 Installed Agent ")]),_c('li',[_c('i',{staticClass:"fas fa-circle fa-2xs text-primary me-2"}),_vm._v(" Real-Time Feedback ")]),_c('li',[_c('i',{staticClass:"fas fa-circle fa-2xs text-primary me-2"}),_vm._v(" Video Dedicated Support ")]),_c('li',[_c('i',{staticClass:"fas fa-circle fa-2xs text-primary me-2"}),_vm._v(" 1 Attacked Targets Per Month ")]),_c('li',[_c('i',{staticClass:"fas fa-circle fa-2xs text-primary me-2"}),_vm._v(" Team Collaboration Tools ")]),_c('li',[_c('i',{staticClass:"fas fa-circle fa-2xs text-primary me-2"}),_vm._v(" Automated Updated Features ")]),_c('li',[_c('i',{staticClass:"fas fa-circle fa-2xs text-primary me-2"}),_vm._v(" 24/7 Life time Support ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dot-shape-bg position-absolute z--1 left--40 bottom--40"},[_c('img',{attrs:{"src":require("@/assets/img/shape/dot-big-square.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricing-header mb-32"},[_c('h3',{staticClass:"package-name text-warning d-block"},[_vm._v("Advanced")]),_c('h4',{staticClass:"display-6 fw-semi-bold"},[_vm._v("$45"),_c('span',[_vm._v("/month")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricing-info mb-4"},[_c('ul',{staticClass:"pricing-feature-list list-unstyled"},[_c('li',[_c('i',{staticClass:"fas fa-circle fa-2xs text-warning me-2"}),_vm._v(" 5 Team ")]),_c('li',[_c('i',{staticClass:"fas fa-circle fa-2xs text-warning me-2"}),_vm._v(" 3 Installed Agent ")]),_c('li',[_c('i',{staticClass:"fas fa-circle fa-2xs text-warning me-2"}),_vm._v(" Real-Time Feedback ")]),_c('li',[_c('i',{staticClass:"fas fa-circle fa-2xs text-warning me-2"}),_vm._v(" Video Dedicated Support ")]),_c('li',[_c('i',{staticClass:"fas fa-circle fa-2xs text-warning me-2"}),_vm._v(" 24 Attacked Targets Per Month ")]),_c('li',[_c('i',{staticClass:"fas fa-circle fa-2xs text-warning me-2"}),_vm._v(" Team Collaboration Tools ")]),_c('li',[_c('i',{staticClass:"fas fa-circle fa-2xs text-warning me-2"}),_vm._v(" Automated Updated Features ")]),_c('li',[_c('i',{staticClass:"fas fa-circle fa-2xs text-warning me-2"}),_vm._v(" 24/7 Life time Support ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricing-header mb-32"},[_c('h3',{staticClass:"package-name text-primary d-block"},[_vm._v("Premium")]),_c('h4',{staticClass:"display-6 fw-semi-bold"},[_vm._v("$75"),_c('span',[_vm._v("/month")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricing-info mb-4"},[_c('ul',{staticClass:"pricing-feature-list list-unstyled"},[_c('li',[_c('i',{staticClass:"fas fa-circle fa-2xs text-primary me-2"}),_vm._v(" 6 Team ")]),_c('li',[_c('i',{staticClass:"fas fa-circle fa-2xs text-primary me-2"}),_vm._v(" 8 Installed Agent ")]),_c('li',[_c('i',{staticClass:"fas fa-circle fa-2xs text-primary me-2"}),_vm._v(" Real-Time Feedback ")]),_c('li',[_c('i',{staticClass:"fas fa-circle fa-2xs text-primary me-2"}),_vm._v(" Video Dedicated Support ")]),_c('li',[_c('i',{staticClass:"fas fa-circle fa-2xs text-primary me-2"}),_vm._v(" 40 Attacked Targets Per Month ")]),_c('li',[_c('i',{staticClass:"fas fa-circle fa-2xs text-primary me-2"}),_vm._v(" Team Collaboration Tools ")]),_c('li',[_c('i',{staticClass:"fas fa-circle fa-2xs text-primary me-2"}),_vm._v(" Automated Updated Features ")]),_c('li',[_c('i',{staticClass:"fas fa-circle fa-2xs text-primary me-2"}),_vm._v(" 24/7 Life time Support ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dot-shape-bg position-absolute z--1 right--40 top--40"},[_c('img',{attrs:{"src":require("@/assets/img/shape/dot-big-square.svg"),"alt":"shape"}})])
}]

export { render, staticRenderFns }