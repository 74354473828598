<template>
  <section class="job-details ptb-120">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-lg-8 pe-lg-5">
          <div class="job-details-wrap">
            <h2>Job Details</h2>
            <p>
              Objectively restore stand-alone markets rather than
              enterprise-wide products. Uniquely underwhelm best-of-breed
              mindshare through adaptive niches. Seamlessly parallel task
              open-source content without resource sucking technology.
            </p>
            <div class="job-details-info mt-5">
              <h3 class="h5">Job Responsibilities</h3>
              <ul class="content-list list-unstyled">
                <li>
                  Be involved in every step of the product design cycle from
                  discovery and user acceptance testing.
                </li>
                <li>
                  Work with BAs, product managers and tech teams to lead the
                  Product Design
                </li>
                <li>
                  Maintain quality of the design process and ensure that when
                  designs are translated into code they accurately.
                </li>
                <li>
                  Accurately estimate design tickets during planning sessions.
                </li>
                <li>
                  Contribute to sketching sessions involving
                  non-designersCreate, and pattern libraries.
                </li>
                <li>
                  Design pixel perfect responsive UI’s and understand that
                  adopting common interface
                </li>
                <li>
                  Interface patterns is better for UX than reinventing the wheel
                </li>
              </ul>
            </div>
            <div class="job-details-info mt-5">
              <h3 class="h5">Job Requirements</h3>
              <ul class="content-list list-unstyled">
                <li>
                  Interactively plagiarize covalent "outside the box" thinking
                  vis-a-vis.
                </li>
                <li>
                  Holisticly communicate integrated channels via backend
                  interfaces. Authoritatively.
                </li>
                <li>
                  Globally actualize effective processes through synergistic
                  ROI. Interactively.
                </li>
              </ul>
            </div>

            <div class="job-details-info mt-5">
              <h3 class="h5">Skill & Experience</h3>
              <ul class="content-list list-unstyled">
                <li>
                  You have at least 3 years’ experience working as a Product
                  Designer.
                </li>
                <li>
                  You have experience using Sketch and InVision or Framer X
                </li>
                <li>
                  You have some previous experience working in an agile
                  environment – Think two-week sprints.
                </li>
                <li>
                  You are familiar using Jira and Confluence in your workflow
                </li>
              </ul>
            </div>

            <img
              src="@/assets/img/apply-now-1.jpg"
              class="img-fluid mt-5 rounded-custom"
              alt="apply"
            />
          </div>
        </div>
        <div class="col-lg-4">
          <div
            class="job-overview-wrap bg-light p-5 sticky-sidebar rounded-custom mt-5 mt-lg-0"
          >
            <h5>Job Overviews</h5>
            <ul class="job-overview-list list-unstyled mt-4">
              <li>
                <i class="far fa-map-marker-alt text-primary"></i>
                <div class="overview-item">
                  <h6 class="mb-0">Location:</h6>
                  <span>London, UK</span>
                </div>
              </li>
              <li>
                <i class="far fa-user text-primary"></i>
                <div class="overview-item">
                  <h6 class="mb-0">Job Title:</h6>
                  <span>Designer</span>
                </div>
              </li>
              <li>
                <i class="far fa-clock text-primary"></i>
                <div class="overview-item">
                  <h6 class="mb-0">Hours:</h6>
                  <span>50h / week</span>
                </div>
              </li>
              <li>
                <i class="far fa-history text-primary"></i>
                <div class="overview-item">
                  <h6 class="mb-0">Rate:</h6>
                  <span>$15 - $25 / hour</span>
                </div>
              </li>
              <li>
                <i class="far fa-wallet text-primary"></i>
                <div class="overview-item">
                  <h6 class="mb-0">Salary:</h6>
                  <span>$35k - $45k</span>
                </div>
              </li>
            </ul>
            <router-link to="/contact" class="btn btn-primary d-block mt-5"
              >Apply now</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
