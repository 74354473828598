<template>
  <div class="main-wrapper">
    <SignupForm />
  </div>
</template>

<script>
import SignupForm from "../../components/other's/SignupForm.vue";
export default {
  components: { SignupForm },
};
</script>
