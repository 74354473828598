<template>
  <section class="home-blog-section ptb-120 bg-light">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-lg-5 col-md-12">
          <div class="section-heading" data-aos="fade-up">
            <h4 class="text-primary h5">Blog</h4>
            <h2>Check our Latest News and Update</h2>
          </div>
        </div>
        <div class="col-lg-7 col-md-12">
          <div class="text-lg-end mb-5 mb-lg-0" data-aos="fade-up">
            <router-link to="/news" class="btn btn-primary"
              >View All Article</router-link
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div
            class="single-article rounded-custom mb-4 mb-lg-0"
            data-aos="fade-up"
            data-aos-delay="50"
          >
            <router-link to="/news-details" class="article-img">
              <img
                src="@/assets/img/blog/blog-1.jpg"
                alt="article"
                class="img-fluid"
              />
            </router-link>
            <div class="article-content p-4">
              <div class="article-category mb-4 d-block">
                <a
                  href="javascript:;"
                  class="d-inline-block text-dark badge bg-warning-soft"
                  >Design</a
                >
              </div>
              <router-link to="/news-details">
                <h2 class="h5 article-title limit-2-line-text">
                  Do you really understand the concept of product value?
                </h2>
              </router-link>
              <p class="limit-2-line-text">
                Society is fragmenting into two parallel realities. In one
                reality, you have infinite upside and opportunity. In the other
                reality, you’ll continue to see the gap between your standard of
                living and those at the top grow more and more.
              </p>

              <a href="javascript:;">
                <div class="d-flex align-items-center pt-4">
                  <div class="avatar">
                    <img
                      src="@/assets/img/testimonial/6.jpg"
                      alt="avatar"
                      width="40"
                      class="img-fluid rounded-circle me-3"
                    />
                  </div>
                  <div class="avatar-info">
                    <h6 class="mb-0 avatar-name">Jane Martin</h6>
                    <span class="small fw-medium text-muted"
                      >April 24, 2021</span
                    >
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div
            class="single-article rounded-custom mb-4 mb-lg-0"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <router-link to="/news-details" class="article-img">
              <img
                src="@/assets/img/blog/blog-2.jpg"
                alt="article"
                class="img-fluid"
              />
            </router-link>
            <div class="article-content p-4">
              <div class="article-category mb-4 d-block">
                <a
                  href="javascript:;"
                  class="d-inline-block text-dark badge bg-primary-soft"
                  >Customer</a
                >
              </div>
              <router-link to="/news-details">
                <h2 class="h5 article-title limit-2-line-text">
                  Why communities help you build better products for your
                  business
                </h2>
              </router-link>
              <p class="limit-2-line-text">
                Society is fragmenting into two parallel realities. In one
                reality, you have infinite upside and opportunity. In the other
                reality, you’ll continue to see the gap between your standard of
                living and those at the top grow more and more.
              </p>

              <a href="javascript:;">
                <div class="d-flex align-items-center pt-4">
                  <div class="avatar">
                    <img
                      src="@/assets/img/testimonial/1.jpg"
                      alt="avatar"
                      width="40"
                      class="img-fluid rounded-circle me-3"
                    />
                  </div>
                  <div class="avatar-info">
                    <h6 class="mb-0 avatar-name">Veronica P. Byrd</h6>
                    <span class="small fw-medium text-muted"
                      >April 24, 2021</span
                    >
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div
            class="single-article rounded-custom mb-4 mb-lg-0 mb-md-0"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            <router-link to="/news-single" class="article-img">
              <img
                src="@/assets/img/blog/blog-3.jpg"
                alt="article"
                class="img-fluid"
              />
            </router-link>
            <div class="article-content p-4">
              <div class="article-category mb-4 d-block">
                <a
                  href="javascript:;"
                  class="d-inline-block text-dark badge bg-danger-soft"
                  >Development</a
                >
              </div>
              <router-link to="/news-details">
                <h2 class="h5 article-title limit-2-line-text">
                  Why communities help you build better products
                </h2>
              </router-link>
              <p class="limit-2-line-text">
                Society is fragmenting into two parallel realities. In one
                reality, you have infinite upside and opportunity. In the other
                reality, you’ll continue to see the gap between your standard of
                living and those at the top grow more and more.
              </p>

              <a href="javascript:;">
                <div class="d-flex align-items-center pt-4">
                  <div class="avatar">
                    <img
                      src="@/assets/img/testimonial/3.jpg"
                      alt="avatar"
                      width="40"
                      class="img-fluid rounded-circle me-3"
                    />
                  </div>
                  <div class="avatar-info">
                    <h6 class="mb-0 avatar-name">Martin Gilbert</h6>
                    <span class="small fw-medium text-muted"
                      >April 24, 2021</span
                    >
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "LatestBlog",
};
</script>

<style></style>
