var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"feature-section ptb-120",class:[_vm.darkBg ? 'bg-dark' : '', _vm.bgLight ? 'bg-light' : '']},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center",class:_vm.hasTitle ? 'row justify-content-center' : 'd-none'},[_vm._m(0)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"feature-grid"},[_c('div',{staticClass:"feature-card shadow-sm highlight-card rounded-custom p-5",class:_vm.darkBg
                ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                : 'bg-white',attrs:{"data-aos":"fade-up","data-aos-delay":"50"}},[_vm._m(1),_vm._m(2),_c('router-link',{staticClass:"link-with-icon text-decoration-none mt-3",attrs:{"to":"/service-single"}},[_vm._v("View Details "),_c('i',{staticClass:"far fa-arrow-right"})])],1),_c('div',{staticClass:"feature-card shadow-sm rounded-custom p-5",class:_vm.darkBg
                ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                : 'bg-white',attrs:{"data-aos":"fade-up","data-aos-delay":"50"}},[_vm._m(3),_vm._m(4),_c('router-link',{staticClass:"link-with-icon text-decoration-none mt-3",attrs:{"to":"/service-single"}},[_vm._v("View Details "),_c('i',{staticClass:"far fa-arrow-right"})])],1),_c('div',{staticClass:"feature-card shadow-sm rounded-custom p-5",class:_vm.darkBg
                ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                : 'bg-white',attrs:{"data-aos":"fade-up","data-aos-delay":"50"}},[_vm._m(5),_vm._m(6),_c('router-link',{staticClass:"link-with-icon text-decoration-none mt-3",attrs:{"to":"/service-single"}},[_vm._v("View Details "),_c('i',{staticClass:"far fa-arrow-right"})])],1),_c('div',{staticClass:"feature-card shadow-sm rounded-custom p-5",class:_vm.darkBg
                ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                : 'bg-white',attrs:{"data-aos":"fade-up","data-aos-delay":"50"}},[_vm._m(7),_vm._m(8),_c('router-link',{staticClass:"link-with-icon text-decoration-none mt-3",attrs:{"to":"/service-single"}},[_vm._v("View Details "),_c('i',{staticClass:"far fa-arrow-right"})])],1),_c('div',{staticClass:"feature-card shadow-sm rounded-custom p-5",class:_vm.darkBg
                ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                : 'bg-white',attrs:{"data-aos":"fade-up","data-aos-delay":"50"}},[_vm._m(9),_vm._m(10),_c('router-link',{staticClass:"link-with-icon text-decoration-none mt-3",attrs:{"to":"/service-single"}},[_vm._v("View Details "),_c('i',{staticClass:"far fa-arrow-right"})])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-6 col-md-10"},[_c('div',{staticClass:"section-heading text-center",attrs:{"data-aos":"fade-up"}},[_c('h2',[_vm._v("Best Services Grow Your Business Value")]),_c('p',[_vm._v(" Globally actualize cost effective with resource maximizing leadership skills. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-box d-inline-block rounded-circle bg-primary-soft mb-32"},[_c('i',{staticClass:"fal fa-analytics icon-sm text-primary"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feature-content"},[_c('h3',{staticClass:"h5"},[_vm._v("Advanced analytics")]),_c('p',[_vm._v(" Synergistically pursue accurate initiatives without economically sound imperatives. ")]),_c('p',[_vm._v(" Professionally architect unique process improvements via optimal. ")]),_c('h6',{staticClass:"mt-4"},[_vm._v("Included with...")]),_c('ul',{staticClass:"list-unstyled mb-0"},[_c('li',{staticClass:"py-1"},[_c('i',{staticClass:"fad fa-check-circle me-2 text-primary"}),_vm._v("High-converting ")]),_c('li',{staticClass:"py-1"},[_c('i',{staticClass:"fad fa-check-circle me-2 text-primary"}),_vm._v("Personal branding ")]),_c('li',{staticClass:"py-1"},[_c('i',{staticClass:"fad fa-check-circle me-2 text-primary"}),_vm._v("Modernized prospecting ")]),_c('li',{staticClass:"py-1"},[_c('i',{staticClass:"fad fa-check-circle me-2 text-primary"}),_vm._v("Clean and modern ")]),_c('li',{staticClass:"py-1"},[_c('i',{staticClass:"fad fa-check-circle me-2 text-primary"}),_vm._v("Showcasing success ")]),_c('li',{staticClass:"py-1"},[_c('i',{staticClass:"fad fa-check-circle me-2 text-primary"}),_vm._v("Bootstrap latest version ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-box d-inline-block rounded-circle bg-success-soft mb-32"},[_c('i',{staticClass:"fal fa-file-chart-line icon-sm text-success"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feature-content"},[_c('h3',{staticClass:"h5"},[_vm._v("Automated Reports")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Synergistically pursue accurate initiatives without economically imperatives. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-box d-inline-block rounded-circle bg-danger-soft mb-32"},[_c('i',{staticClass:"fal fa-user-friends icon-sm text-danger"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feature-content"},[_c('h3',{staticClass:"h5"},[_vm._v("User Journey Flow")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Quickly productize prospective value before collaborative benefits. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-box d-inline-block rounded-circle bg-dark-soft mb-32"},[_c('i',{staticClass:"fal fa-spell-check icon-sm text-dark"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feature-content"},[_c('h3',{staticClass:"h5"},[_vm._v("Compare A/B Testing")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Credibly disintermediate functional processes for team driven action. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-box d-inline-block rounded-circle bg-warning-soft mb-32"},[_c('i',{staticClass:"fal fa-cog icon-sm text-warning"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feature-content"},[_c('h3',{staticClass:"h5"},[_vm._v("Easy Customization")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Dynamically develop ubiquitous opportunities whereas relationships. ")])])
}]

export { render, staticRenderFns }