<template>
  <section
    id="our-team"
    class="team-section ptb-120"
    :class="[bgLight ? 'bg-light' : '']"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-12">
          <div class="section-heading text-center">
            <h5 class="h6 text-primary">Our Team</h5>
            <h2>The People Behind Quiety</h2>
            <p>
              Intrinsicly strategize cutting-edge before interoperable
              applications incubate extensive expertise through integrated
              intellectual capital.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="team-single-wrap mb-5">
            <div class="team-img rounded-custom">
              <img
                src="@/assets/img/team/team-1.jpg"
                alt="team"
                class="img-fluid position-relative"
              />
              <ul
                class="list-unstyled team-social-list d-flex flex-column mb-0"
              >
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-linkedin-in"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-twitter"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-github"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-facebook-f"></i></a>
                </li>
              </ul>
            </div>
            <div class="team-info mt-4 text-center">
              <h5 class="h6 mb-1">John Sullivan</h5>
              <p class="text-muted small mb-0">Front End Developer</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="team-single-wrap mb-5">
            <div class="team-img rounded-custom">
              <img
                src="@/assets/img/team/team-2.jpg"
                alt="team"
                class="img-fluid position-relative"
              />
              <ul
                class="list-unstyled team-social-list d-flex flex-column mb-0"
              >
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-linkedin-in"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-twitter"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-github"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-facebook-f"></i></a>
                </li>
              </ul>
            </div>
            <div class="team-info mt-4 text-center">
              <h5 class="h6 mb-1">John Sullivan</h5>
              <p class="text-muted small mb-0">Front End Developer</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="team-single-wrap mb-5">
            <div class="team-img rounded-custom">
              <img
                src="@/assets/img/team/team-3.jpg"
                alt="team"
                class="img-fluid position-relative"
              />
              <ul
                class="list-unstyled team-social-list d-flex flex-column mb-0"
              >
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-linkedin-in"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-twitter"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-github"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-facebook-f"></i></a>
                </li>
              </ul>
            </div>
            <div class="team-info mt-4 text-center">
              <h5 class="h6 mb-1">John Sullivan</h5>
              <p class="text-muted small mb-0">Front End Developer</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="team-single-wrap mb-5">
            <div class="team-img rounded-custom">
              <img
                src="@/assets/img/team/team-4.jpg"
                alt="team"
                class="img-fluid position-relative"
              />
              <ul
                class="list-unstyled team-social-list d-flex flex-column mb-0"
              >
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-linkedin-in"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-twitter"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-github"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-facebook-f"></i></a>
                </li>
              </ul>
            </div>
            <div class="team-info mt-4 text-center">
              <h5 class="h6 mb-1">John Sullivan</h5>
              <p class="text-muted small mb-0">Front End Developer</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="team-single-wrap mb-5">
            <div class="team-img rounded-custom">
              <img
                src="@/assets/img/team/team-5.jpg"
                alt="team"
                class="img-fluid position-relative"
              />
              <ul
                class="list-unstyled team-social-list d-flex flex-column mb-0"
              >
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-linkedin-in"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-twitter"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-github"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-facebook-f"></i></a>
                </li>
              </ul>
            </div>
            <div class="team-info mt-4 text-center">
              <h5 class="h6 mb-1">John Sullivan</h5>
              <p class="text-muted small mb-0">Front End Developer</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="team-single-wrap mb-5">
            <div class="team-img rounded-custom">
              <img
                src="@/assets/img/team/team-6.jpg"
                alt="team"
                class="img-fluid position-relative"
              />
              <ul
                class="list-unstyled team-social-list d-flex flex-column mb-0"
              >
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-linkedin-in"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-twitter"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-github"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-facebook-f"></i></a>
                </li>
              </ul>
            </div>
            <div class="team-info mt-4 text-center">
              <h5 class="h6 mb-1">John Sullivan</h5>
              <p class="text-muted small mb-0">Front End Developer</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="team-single-wrap mb-5">
            <div class="team-img rounded-custom">
              <img
                src="@/assets/img/team/team-7.jpg"
                alt="team"
                class="img-fluid position-relative"
              />
              <ul
                class="list-unstyled team-social-list d-flex flex-column mb-0"
              >
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-linkedin-in"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-twitter"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-github"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-facebook-f"></i></a>
                </li>
              </ul>
            </div>
            <div class="team-info mt-4 text-center">
              <h5 class="h6 mb-1">John Sullivan</h5>
              <p class="text-muted small mb-0">Front End Developer</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="team-single-wrap mb-5">
            <div class="team-img rounded-custom">
              <img
                src="@/assets/img/team/team-8.jpg"
                alt="team"
                class="img-fluid position-relative"
              />
              <ul
                class="list-unstyled team-social-list d-flex flex-column mb-0"
              >
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-linkedin-in"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-twitter"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-github"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-facebook-f"></i></a>
                </li>
              </ul>
            </div>
            <div class="team-info mt-4 text-center">
              <h5 class="h6 mb-1">John Sullivan</h5>
              <p class="text-muted small mb-0">Front End Developer</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    bgLight: String,
  },
};
</script>
