<template>
  <section class="app-two-feature-two pt-60 pb-120">
    <div class="container">
      <div class="row align-items-center justify-content-lg-between">
        <div class="col-lg-6 col-xl-6 col-md-12">
          <div class="app-two-feature-two-img">
            <img
              src="@/assets/img/app-two-feature-phone-with-shape.png"
              alt="feature"
              class="img-fluid"
            />
          </div>
        </div>
        <div class="col-xl-5 col-lg-5">
          <div class="app-two-feature-two-right">
            <div class="feature-content-wrap">
              <h4 class="h6">Best Features</h4>
              <h2>Boost up your Phone in just one click</h2>
              <p>
                Completely evolve orthogonal sources via cooperative data.
                Dynamically promote high standards in resources after timely
                catalysts for alignments and visionary quality vectors.
              </p>
            </div>
            <div class="app-two-feature-two-content">
              <ul class="list-unstyled d-flex flex-wrap list-two-col mt-4">
                <li class="py-1">
                  <i class="fad fa-check-circle me-2"></i>Based on Bootstrap
                  Grid
                </li>
                <li class="py-1">
                  <i class="fad fa-check-circle me-2"></i>Online Learning
                </li>
                <li class="py-1">
                  <i class="fad fa-check-circle me-2"></i>Track your spending
                </li>
                <li class="py-1">
                  <i class="fad fa-check-circle me-2"></i>Unlimited Download
                </li>
                <li class="py-1">
                  <i class="fad fa-check-circle me-2"></i>Responisve Design
                </li>
                <li class="py-1">
                  <i class="fad fa-check-circle me-2"></i> Always Be in Touch
                </li>
              </ul>
              <div class="action-btns mt-5">
                <router-link class="btn app-two-btn" to="/about-us"
                  >Learn About More</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ImgContentNine",
};
</script>
