<template>
  <section class="why-choose-us pt-60 pb-120">
    <div class="container">
      <div class="row justify-content-lg-between align-items-center">
        <div class="col-lg-6 col-12">
          <div class="feature-img-holder mt-4 mt-lg-0 mt-xl-0">
            <img
              src="@/assets/img/screen/widget-7.png"
              class="img-fluid"
              alt="feature-image"
            />
          </div>
        </div>
        <div class="col-lg-5 col-12">
          <div class="why-choose-content mt-5 mt-lg-0">
            <div
              class="icon-box rounded-custom bg-primary shadow-sm d-inline-block"
            >
              <i class="fal fa-shield-check fa-2x text-white"></i>
            </div>
            <h2>Advanced Analytics, Understand Business</h2>
            <p>
              Distinctively promote parallel vortals with ubiquitous e-markets.
              Proactively benchmark turnkey optimize next-generation strategic
              leadership without resource sucking ideas.
            </p>
            <ul class="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>Thought
                leadership
              </li>
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>Personal
                branding
              </li>
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>Modernized
                prospecting
              </li>
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>Better win
                rates
              </li>
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>Showcasing
                success
              </li>
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>Sales
                compliance
              </li>
            </ul>
            <router-link
              to="/about-us"
              class="read-more-link text-decoration-none"
              >Know More About Us <i class="far fa-arrow-right ms-2"></i
            ></router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FeatureSeven",
};
</script>
