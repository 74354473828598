<template>
  <section class="work-process ptb-120 bg-dark text-white">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-6">
          <div class="section-heading text-center" data-aos="fade-up">
            <h2>We Follow Our Work Process</h2>
            <p>
              Conveniently mesh cooperative services via magnetic outsourcing.
              Dynamically grow value whereas accurate e-commerce parallel
              quality vectors.
            </p>
          </div>
        </div>
      </div>
      <div class="row d-flex align-items-center">
        <div class="col-md-6 col-lg-3">
          <div
            class="process-card-two text-center px-4 py-5 rounded-custom mt-4"
            data-aos="fade-up"
            data-aos-delay="50"
          >
            <div
              class="process-icon border border-light bg-custom-light rounded-custom p-3"
            >
              <i class="fad fa-folder-tree fa-2x"></i>
            </div>
            <h3 class="h5">Research</h3>
            <p class="mb-0">Uniquely pursue compelling initiatives expanded.</p>
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div
            class="process-card-two text-center px-4 py-5 rounded-custom mt-4"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div
              class="process-icon border border-light bg-custom-light rounded-custom p-3"
            >
              <i class="fad fa-bezier-curve fa-2x"></i>
            </div>
            <h3 class="h5">Designing</h3>
            <p class="mb-0">Uniquely pursue compelling initiatives expanded.</p>
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div
            class="process-card-two text-center px-4 py-5 rounded-custom mt-4"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            <div
              class="process-icon border border-light bg-custom-light rounded-custom p-3"
            >
              <i class="fad fa-layer-group fa-2x"></i>
            </div>
            <h3 class="h5">Building</h3>
            <p class="mb-0">Uniquely pursue compelling initiatives expanded.</p>
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div
            class="process-card-two text-center px-4 py-5 rounded-custom mt-4"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div
              class="process-icon border border-light bg-custom-light rounded-custom p-3"
            >
              <i class="fad fa-truck fa-2x"></i>
            </div>
            <h3 class="h5">Deliver</h3>
            <p class="mb-0">Uniquely pursue compelling initiatives expanded.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WorkProcessTwo',
};
</script>
