<template>
  <section class="sign-up-in-section bg-dark ptb-60 page-header">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-5 col-md-8 col-12">
          <router-link to="/" class="mb-4 d-block text-center"
            ><img
              src="@/assets/img/logo-white.png"
              alt="logo"
              class="img-fluid"
          /></router-link>
          <div class="register-wrap p-5 bg-light shadow rounded-custom">
            <h1 class="fw-bold h3">Forgot your Password?</h1>
            <p class="text-muted">
              Don't worry. Type your email and we will send a password recovery
              link to your email..
            </p>

            <form action="#" class="mt-5 register-form">
              <div class="row">
                <div class="col-sm-12">
                  <label for="email" class="mb-1"
                    >Email <span class="text-danger">*</span></label
                  >
                  <div class="input-group mb-3">
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Enter your email"
                      id="email"
                      aria-label="email"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <button
                    type="submit"
                    class="btn btn-primary mt-3 d-block w-100"
                  >
                    Reset Password
                  </button>
                </div>
              </div>
              <p class="font-monospace fw-medium text-center mt-3 pt-4 mb-0">
                <router-link to="/login" class="text-decoration-none"
                  >Back to login page</router-link
                >
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
