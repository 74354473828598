<template>
  <section class="feature-section ptb-120">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-12">
          <div class="section-heading text-center" data-aos="fade-up">
            <h4 class="text-primary h5">Build Quality</h4>
            <h2>Our Special Features</h2>
            <p>
              Authoritatively underwhelm excellent methodologies via premium
              expertise competitive than open-source imperatives disseminate.
            </p>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-lg-4 col-md-6">
          <div class="position-relative" data-aos="fade-up" data-aos-delay="50">
            <div
              class="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2"
            >
              <div
                class="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32"
              >
                <i class="fad fa-shield-alt text-primary fa-2x"></i>
              </div>
              <h3 class="h5">Quality First</h3>
              <p class="mb-0">
                Professionally integrate high standards applications before user
                friendly leadership skills.
              </p>
            </div>
            <!--pattern start-->
            <div class="dot-shape-bg position-absolute z--1 left--40 top--40">
              <img src="@/assets/img/shape/dot-big-square.svg" alt="shape" />
            </div>
            <!--pattern end-->
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div
            class="position-relative"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div
              class="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0"
            >
              <div
                class="feature-icon d-inline-block bg-danger-soft rounded-circle mb-32"
              >
                <i class="fad fa-comment-alt-smile text-danger fa-2x"></i>
              </div>
              <h3 class="h5">Communication</h3>
              <p class="mb-0">
                Professionally integrate high standards applications before user
                friendly leadership skills.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div
            class="position-relative"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            <div
              class="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0"
            >
              <div
                class="feature-icon d-inline-block bg-success-soft rounded-circle mb-32"
              >
                <i class="fad fa-grin-stars text-success fa-2x"></i>
              </div>
              <h3 class="h5">Reliability</h3>
              <p class="mb-0">
                Professionally integrate high standards applications before user
                friendly leadership skills.
              </p>
            </div>
            <!--pattern start-->
            <div
              class="dot-shape-bg position-absolute z--1 right--40 bottom--40"
            >
              <img src="@/assets/img/shape/dot-big-square.svg" alt="shape" />
            </div>
            <!--pattern end-->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeatureFour',
};
</script>
