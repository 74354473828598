<template>
  <section class="app-two-feature ptb-120">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10">
          <div class="section-heading text-center">
            <h2>Explore Premium Features</h2>
            <p>
              A bit of how's your father he nicked it charles daft plast
              lavator.
            </p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-xl-10">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12">
              <div
                class="app-two-single-feature d-md-flex align-items-start mb-30"
              >
                <div
                  class="app-two-single-feature-icon box-one me-3 mb-4 mb-md-0"
                >
                  <i class="far fa-file-edit"></i>
                </div>
                <div class="app-two-single-feature-content">
                  <h3 class="h5">Easy to Customized</h3>
                  <p>
                    Authoritatively productize orthogonal networks rather than
                    state of the art interfaces.
                  </p>
                  <router-link
                    class="link-with-icon text-decoration-none"
                    to="/service-single"
                    >View Details <i class="far fa-arrow-right"></i
                  ></router-link>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12">
              <div
                class="app-two-single-feature d-md-flex align-items-start mb-30"
              >
                <div
                  class="app-two-single-feature-icon box-two me-3 mb-4 mb-md-0"
                >
                  <i class="far fa-bell"></i>
                </div>
                <div class="app-two-single-feature-content">
                  <h3 class="h5">Mobile App</h3>
                  <p>
                    Enthusiastically envisioneer integrated catalysts for change
                    before fully researched vortals.
                  </p>
                  <router-link
                    class="link-with-icon text-decoration-none"
                    to="/service-single"
                    >View Details <i class="far fa-arrow-right"></i
                  ></router-link>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12">
              <div
                class="app-two-single-feature d-md-flex align-items-start mb-30"
              >
                <div
                  class="app-two-single-feature-icon box-three me-3 mb-4 mb-md-0"
                >
                  <i class="far fa-pen-nib"></i>
                </div>
                <div class="app-two-single-feature-content">
                  <h3 class="h5">Expand your reach</h3>
                  <p>
                    Authoritatively syndicate focused ideas whereas
                    revolutionary outsourcing.
                  </p>
                  <router-link
                    class="link-with-icon text-decoration-none"
                    to="/service-single"
                    >View Details <i class="far fa-arrow-right"></i
                  ></router-link>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12">
              <div
                class="app-two-single-feature d-md-flex align-items-start mb-30"
              >
                <div
                  class="app-two-single-feature-icon box-four me-3 mb-4 mb-md-0"
                >
                  <i class="far fa-cloud-download-alt"></i>
                </div>
                <div class="app-two-single-feature-content">
                  <h3 class="h5">Free Download</h3>
                  <p>
                    Rapidiously reintermediate plug-and-play potentialities via
                    cross-unit e-markets.
                  </p>
                  <router-link
                    class="link-with-icon text-decoration-none"
                    to="/service-single"
                    >View Details <i class="far fa-arrow-right"></i
                  ></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
