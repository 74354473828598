<template>
  <section
    class="feature-section ptb-120"
    :class="[darkBg ? 'bg-dark' : '', bgLight ? 'bg-light' : '']"
  >
    <div class="container">
      <div
        class="row justify-content-center"
        :class="hasTitle ? 'row justify-content-center' : 'd-none'"
      >
        <div class="col-lg-6 col-md-10">
          <div class="section-heading text-center" data-aos="fade-up">
            <h2>Best Services Grow Your Business Value</h2>
            <p>
              Globally actualize cost effective with resource maximizing
              leadership skills.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="feature-grid">
            <div
              class="feature-card shadow-sm highlight-card rounded-custom p-5"
              :class="
                darkBg
                  ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                  : 'bg-white'
              "
              data-aos="fade-up"
              data-aos-delay="50"
            >
              <div
                class="icon-box d-inline-block rounded-circle bg-primary-soft mb-32"
              >
                <i class="fal fa-analytics icon-sm text-primary"></i>
              </div>
              <div class="feature-content">
                <h3 class="h5">Advanced analytics</h3>
                <p>
                  Synergistically pursue accurate initiatives without
                  economically sound imperatives.
                </p>
                <p>
                  Professionally architect unique process improvements via
                  optimal.
                </p>
                <h6 class="mt-4">Included with...</h6>
                <ul class="list-unstyled mb-0">
                  <li class="py-1">
                    <i class="fad fa-check-circle me-2 text-primary"></i
                    >High-converting
                  </li>
                  <li class="py-1">
                    <i class="fad fa-check-circle me-2 text-primary"></i
                    >Personal branding
                  </li>
                  <li class="py-1">
                    <i class="fad fa-check-circle me-2 text-primary"></i
                    >Modernized prospecting
                  </li>
                  <li class="py-1">
                    <i class="fad fa-check-circle me-2 text-primary"></i>Clean
                    and modern
                  </li>
                  <li class="py-1">
                    <i class="fad fa-check-circle me-2 text-primary"></i
                    >Showcasing success
                  </li>
                  <li class="py-1">
                    <i class="fad fa-check-circle me-2 text-primary"></i
                    >Bootstrap latest version
                  </li>
                </ul>
              </div>
              <router-link
                to="/service-single"
                class="link-with-icon text-decoration-none mt-3"
                >View Details <i class="far fa-arrow-right"></i
              ></router-link>
            </div>
            <div
              class="feature-card shadow-sm rounded-custom p-5"
              :class="
                darkBg
                  ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                  : 'bg-white'
              "
              data-aos="fade-up"
              data-aos-delay="50"
            >
              <div
                class="icon-box d-inline-block rounded-circle bg-success-soft mb-32"
              >
                <i class="fal fa-file-chart-line icon-sm text-success"></i>
              </div>
              <div class="feature-content">
                <h3 class="h5">Automated Reports</h3>
                <p class="mb-0">
                  Synergistically pursue accurate initiatives without
                  economically imperatives.
                </p>
              </div>
              <router-link
                to="/service-single"
                class="link-with-icon text-decoration-none mt-3"
                >View Details <i class="far fa-arrow-right"></i
              ></router-link>
            </div>
            <div
              class="feature-card shadow-sm rounded-custom p-5"
              :class="
                darkBg
                  ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                  : 'bg-white'
              "
              data-aos="fade-up"
              data-aos-delay="50"
            >
              <div
                class="icon-box d-inline-block rounded-circle bg-danger-soft mb-32"
              >
                <i class="fal fa-user-friends icon-sm text-danger"></i>
              </div>
              <div class="feature-content">
                <h3 class="h5">User Journey Flow</h3>
                <p class="mb-0">
                  Quickly productize prospective value before collaborative
                  benefits.
                </p>
              </div>
              <router-link
                to="/service-single"
                class="link-with-icon text-decoration-none mt-3"
                >View Details <i class="far fa-arrow-right"></i
              ></router-link>
            </div>
            <div
              class="feature-card shadow-sm rounded-custom p-5"
              :class="
                darkBg
                  ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                  : 'bg-white'
              "
              data-aos="fade-up"
              data-aos-delay="50"
            >
              <div
                class="icon-box d-inline-block rounded-circle bg-dark-soft mb-32"
              >
                <i class="fal fa-spell-check icon-sm text-dark"></i>
              </div>
              <div class="feature-content">
                <h3 class="h5">Compare A/B Testing</h3>
                <p class="mb-0">
                  Credibly disintermediate functional processes for team driven
                  action.
                </p>
              </div>
              <router-link
                to="/service-single"
                class="link-with-icon text-decoration-none mt-3"
                >View Details <i class="far fa-arrow-right"></i
              ></router-link>
            </div>
            <div
              class="feature-card shadow-sm rounded-custom p-5"
              :class="
                darkBg
                  ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                  : 'bg-white'
              "
              data-aos="fade-up"
              data-aos-delay="50"
            >
              <div
                class="icon-box d-inline-block rounded-circle bg-warning-soft mb-32"
              >
                <i class="fal fa-cog icon-sm text-warning"></i>
              </div>
              <div class="feature-content">
                <h3 class="h5">Easy Customization</h3>
                <p class="mb-0">
                  Dynamically develop ubiquitous opportunities whereas
                  relationships.
                </p>
              </div>
              <router-link
                to="/service-single"
                class="link-with-icon text-decoration-none mt-3"
                >View Details <i class="far fa-arrow-right"></i
              ></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FeatureGrid",
  props: {
    hasTitle: String,
    darkBg: String,
    bgLight: String,
  },
};
</script>
