<template>
  <section class="feature-section-two ptb-120">
    <div class="container">
      <div
        class="row align-items-center justify-content-lg-between justify-content-center"
      >
        <div class="col-lg-6 col-md-12">
          <div class="feature-content-wrap">
            <div class="section-heading">
              <h2>We are development experts on all technologies</h2>
              <p>
                Quickly incubate functional channels with multidisciplinary
                architectures authoritatively fabricate formulate exceptional
                innovation.
              </p>
            </div>
            <ul class="list-unstyled mb-0">
              <li class="d-flex align-items-start mb-4">
                <div class="icon-box bg-primary rounded me-4">
                  <i class="fas fa-bezier-curve text-white"></i>
                </div>
                <div class="icon-content">
                  <h3 class="h5">Pixel Perfect Design</h3>
                  <p>
                    Progressively foster enterprise-wide systems whereas equity
                    invested web-readiness harness installed expedite virtual
                    networks.
                  </p>
                </div>
              </li>
              <li class="d-flex align-items-start mb-4">
                <div class="icon-box bg-danger rounded me-4">
                  <i class="fas fa-fingerprint text-white"></i>
                </div>
                <div class="icon-content">
                  <h3 class="h5">Unique &amp; Minimal Design</h3>
                  <p>
                    Dramatically administrate progressive metrics without
                    error-free globally simplify standardized plagiarize
                    technically sound.
                  </p>
                </div>
              </li>
              <li class="d-flex align-items-start mb-4 mb-lg-0">
                <div class="icon-box bg-dark rounded me-4">
                  <i class="fas fa-headset text-white"></i>
                </div>
                <div class="icon-content">
                  <h3 class="h5">24/7 Free Online Support</h3>
                  <p>
                    Interactively whiteboard transparent testing procedures
                    before bricks-and-clicks initiatives competencies.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 col-md-7">
          <div class="feature-img-wrap">
            <img
              src="@/assets/img/feature-hero-img.svg"
              alt="feature image"
              class="img-fluid rounded-custom"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeatureFive',
};
</script>
