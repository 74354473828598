<template>
  <section class="hero-section hero-four ptb-120 text-white bg-dark">
    <div class="container">
      <div
        class="row justify-content-center text-center text-lg-start align-items-center"
      >
        <div class="col-lg-6 col-md-10">
          <div class="hero-content-wrap mt-5 mt-lg-0 mt-xl-0">
            <h1 class="fw-bold display-5" data-aos="fade-up">
              Build Better App with Quiety Software
            </h1>
            <p class="lead" data-aos="fade-up">
              Proactively coordinate quality quality vectors vis-a-vis supply
              chains. Quickly engage client-centric web services.
            </p>
            <div
              class="action-btns mt-5"
              data-aos="fade-up"
              data-aos-delay="50"
            >
              <router-link to="/request-for-demo" class="btn btn-primary me-3"
                >Request Demo</router-link
              >
              <router-link to="/contact" class="btn btn-outline-light"
                >Contact Us</router-link
              >
            </div>
            <ul
              class="nav subscribe-feature-list d-flex justify-content-center justify-content-lg-start w-100 mt-3"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <li class="nav-item">
                <span class="ms-0"
                  ><i class="far fa-check-circle text-primary me-2"></i>Free
                  14-day trial</span
                >
              </li>
              <li class="nav-item">
                <span
                  ><i class="far fa-check-circle text-primary me-2"></i>No
                  credit card required</span
                >
              </li>
            </ul>

            <div
              class="d-flex justify-content-center justify-content-lg-start mt-5"
              data-aos="fade-up"
              data-aos-delay="150"
            >
              <img
                src="@/assets/img/awards-01.svg"
                alt="awards"
                class="me-4 img-fluid"
              />
              <img
                src="@/assets/img/awards-02.svg"
                alt="awards"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-8">
          <div
            class="hero-img-wrap position-relative app-screen-bg mt-5"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <!--animated shape start-->
            <ul
              class="position-absolute animate-element parallax-element shape-service d-none d-md-block d-lg-block"
            >
              <li class="layer" data-depth="0.03">
                <img
                  src="@/assets/img/color-shape/image-4.svg"
                  alt="shape"
                  class="img-fluid position-absolute color-shape-1"
                />
              </li>
              <li class="layer" data-depth="0.02">
                <img
                  src="@/assets/img/color-shape/feature-2.svg"
                  alt="shape"
                  class="img-fluid position-absolute color-shape-2 z-5"
                />
              </li>
              <li class="layer" data-depth="0.03">
                <img
                  src="@/assets/img/color-shape/feature-3.svg"
                  alt="shape"
                  class="img-fluid position-absolute color-shape-3"
                />
              </li>
            </ul>
            <!--animated shape end-->
            <img
              src="@/assets/img/screen/app-screen-on-hand.png"
              alt="hero image"
              class="img-fluid position-relative z-5"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HeroFour",
};
</script>
