import Vue from "vue";
import AOS from 'aos'
import App from "./App.vue";
import router from "./router";
import CoolLightBox from "vue-cool-lightbox";

//Bootstrap CSS,JS
import "bootstrap";

//CSS
import "./assets/scss/main.scss";
//Font Awesome
import './assets/fonts/fontawesome-all.min.css';

// Swiper
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
//AOS
import 'aos/dist/aos.css'

Vue.use(CoolLightBox);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
  mounted() {
    AOS.init()
  }
}).$mount("#app");
