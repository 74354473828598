<template>
  <section class="faq-section ptb-120 bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7 col-12">
          <div class="section-heading text-center">
            <h4 class="h5 text-primary">FAQ</h4>
            <h2>Frequently Asked Questions</h2>
            <p>
              Completely whiteboard top-line channels and fully tested value.
              Competently generate testing procedures before visionary
              maintainable growth strategies for maintainable.
            </p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-7 col-12">
          <div class="accordion faq-accordion" id="accordionExample">
            <div class="accordion-item border border-2 active">
              <h5 class="accordion-header" id="faq-1">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-1"
                  aria-expanded="true"
                >
                  How does back pricing work?
                </button>
              </h5>
              <div
                id="collapse-1"
                class="accordion-collapse collapse show"
                aria-labelledby="faq-1"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Dramatically formulate cross-unit products with web-enabled
                  action items. Quickly maximize extensible methods of
                  empowerment without out-of-the-box initiatives. Proactively
                  myocardinate functional total linkage rather than seamless
                  information. Holisticly fabricate timely initiatives vis-a-vis
                  high-quality imperatives. Continually deploy open-source
                  content through professional customer service.
                </div>
              </div>
            </div>
            <div class="accordion-item border border-2">
              <h5 class="accordion-header" id="faq-2">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-2"
                  aria-expanded="false"
                >
                  Can you show me an example?
                </button>
              </h5>
              <div
                id="collapse-2"
                class="accordion-collapse collapse"
                aria-labelledby="faq-2"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Continually innovate technically sound internal or "organic"
                  sources with market positioning content. Completely drive
                  optimal intellectual capital vis-a-vis global human capital.
                  Intrinsicly administrate robust materials and bleeding-edge
                  resources. Dynamically envisioneer next-generation markets
                  vis-a-vis market-driven applications
                </div>
              </div>
            </div>
            <div class="accordion-item border border-2">
              <h5 class="accordion-header" id="faq-3">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-3"
                  aria-expanded="false"
                >
                  How do I processing I need?
                </button>
              </h5>
              <div
                id="collapse-3"
                class="accordion-collapse collapse"
                aria-labelledby="faq-3"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Phosfluorescently deliver cooperative testing procedures after
                  integrated communities. Dramatically simplify
                  resource-leveling models with unique outsourcing.
                  Professionally simplify covalent partnerships whereas market
                  positioning best practices. Collaboratively utilize magnetic
                  technology for robust technology.
                </div>
              </div>
            </div>

            <div class="accordion-item border border-2">
              <h5 class="accordion-header" id="faq-4">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-4"
                  aria-expanded="false"
                >
                  What happens if I go over my limit?
                </button>
              </h5>
              <div
                id="collapse-4"
                class="accordion-collapse collapse"
                aria-labelledby="faq-4"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Credibly facilitate leveraged process improvements for equity
                  invested infrastructures. Continually mesh top-line human
                  capital with backward-compatible outsourcing. Rapidiously
                  coordinate intuitive deliverables rather than parallel
                  metrics. Interactively monetize customer directed convergence
                  and parallel sources. Enthusiastically architect
                  client-centric e-services whereas granular e-commerce.
                </div>
              </div>
            </div>

            <div class="accordion-item border border-2">
              <h5 class="accordion-header" id="faq-5">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-5"
                  aria-expanded="false"
                >
                  How do I calculate how much processing I need?
                </button>
              </h5>
              <div
                id="collapse-5"
                class="accordion-collapse collapse"
                aria-labelledby="faq-5"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Distinctively enable premier potentialities through market
                  positioning models. Distinctively extend unique infomediaries
                  without enterprise-wide ideas. Objectively deploy
                  multifunctional catalysts for change for installed base
                  content. Seamlessly create go forward convergence through
                  quality schemas. Objectively deploy cross-media leadership
                  skills through customer directed sources.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FaqAccordion',
};
</script>
