<template>
  <section class="feature-section two-bg-dark-light ptb-120">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-lg-6 col-md-6">
          <div
            class="image-wrap mb-5 mb-md-0 mb-lg-0 mb-xl-0"
            data-aos="fade-up"
          >
            <img
              src="@/assets/img/dashboard-img.png"
              alt="feature img"
              class="img-fluid shadow rounded-custom"
            />
          </div>
        </div>
        <div class="col-lg-5 col-md-6">
          <div
            class="feature-content-wrap"
            data-aos="fade-up"
            data-aos-delay="50"
          >
            <h4 class="h5 text-primary">Advanced Features</h4>
            <h2>Actionable Sale Reports to Grow your Business</h2>
            <p>
              Intrinsicly network transparent services whereas B2C ROI. Globally
              create installed base quality vectors after innovative.
            </p>
            <ul class="list-unstyled mt-5">
              <li class="d-flex align-items-start mb-4">
                <div class="icon-box bg-primary rounded me-4">
                  <i class="fas fa-bezier-curve text-white"></i>
                </div>
                <div class="icon-content">
                  <h3 class="h5">Pixel Perfect Design</h3>
                  <p>
                    Progressively foster enterprise-wide systems whereas equity
                    invested web-readiness harness installed.
                  </p>
                </div>
              </li>
              <li class="d-flex align-items-start mb-4">
                <div class="icon-box bg-danger rounded me-4">
                  <i class="fas fa-fingerprint text-white"></i>
                </div>
                <div class="icon-content">
                  <h3 class="h5">Unique &amp; Minimal Design</h3>
                  <p>
                    Dramatically administrate progressive metrics without
                    error-free globally simplify standardized.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FetureThree",
};
</script>
