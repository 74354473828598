<template>
  <section
    class="cta-section bg-dark ptb-120 position-relative overflow-hidden"
  >
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-lg-5 col-md-12">
          <div class="position-relative z-5">
            <h2>Actionable Reports to Grow your Business</h2>
            <p>
              Conveniently syndicate client-centric users for transparent
              scenarios. Uniquely evolve partnerships rather than cross-platform
              interfaces.
            </p>
            <router-link to="/contact" class="btn btn-primary mt-4"
              >Get Start Now</router-link
            >
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div
            class="row align-items-center justify-content-center position-relative z-2"
          >
            <div class="col-md-6">
              <div
                class="cta-card rounded-custom text-center shadow p-5 bg-white my-4"
              >
                <h3 class="display-5 fw-bold">9/10</h3>
                <p class="mb-0">Overall our customer satisfaction score</p>
              </div>
              <div
                class="cta-card rounded-custom text-center shadow p-5 bg-white my-4"
              >
                <h3 class="display-5 fw-bold">15K+</h3>
                <p class="mb-0">Happy customer in worldwide</p>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="cta-card rounded-custom text-center shadow p-5 bg-white"
              >
                <h3 class="display-5 fw-bold">95%</h3>
                <p class="mb-0">Our Customer customer satisfaction score</p>
              </div>
            </div>
            <div class="bg-circle rounded-circle position-absolute z--1">
              <img
                src="@/assets/img/shape/blob.svg"
                alt="feature image"
                class="img-fluid rounded"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light left-30"
      ></div>
      <div
        class="bg-circle rounded-circle circle-shape-1 position-absolute bg-warning left-5"
      ></div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CtaThree",
};
</script>
