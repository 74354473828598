<template>
  <section class="feature-section ptb-120">
    <div class="container">
      <div class="row align-items-lg-center justify-content-between">
        <div class="col-lg-5 order-lg-2 mb-7 mb-lg-0">
          <div class="mb-4" data-aos="fade-up">
            <h2>Experience your Product with Integration</h2>
            <p>
              Conveniently drive stand-alone total linkage for process-centric
              content enthusiastically administrate. Dramatically grow high
              standards in customer service for applications.
            </p>
          </div>
          <ul
            class="list-unstyled d-flex flex-wrap list-two-col mt-5"
            data-aos="fade-up"
          >
            <li>
              <span class="d-block mb-4"
                ><i class="fal fa-user fa-2x text-primary"></i
              ></span>
              <h3 class="h5">Customer analysis</h3>
              <p>Objectively productize exceptional via customized.</p>
            </li>
            <li>
              <span class="d-block mb-4"
                ><i class="fal fa-watch fa-2x text-primary"></i
              ></span>
              <h3 class="h5">Real time metrics</h3>
              <p>
                Interactively integrate extensible users maximizing resource.
              </p>
            </li>
          </ul>
        </div>
        <div class="col-lg-6 order-lg-1">
          <div class="pr-lg-4 position-relative" data-aos="fade-up">
            <!--animated shape start-->
            <ul
              class="position-absolute animate-element parallax-element shape-service z--1 hide-medium"
            >
              <li class="layer" data-depth="0.03">
                <img
                  src="@/assets/img/color-shape/image-2.svg"
                  alt="shape"
                  class="img-fluid position-absolute color-shape-1"
                />
              </li>
              <li class="layer" data-depth="0.02">
                <img
                  src="@/assets/img/color-shape/feature-2.svg"
                  alt="shape"
                  class="img-fluid position-absolute color-shape-2 z-5"
                />
              </li>
              <li class="layer" data-depth="0.03">
                <img
                  src="@/assets/img/color-shape/feature-3.svg"
                  alt="shape"
                  class="img-fluid position-absolute color-shape-3"
                />
              </li>
            </ul>
            <!--animated shape end-->
            <div
              class="bg-light text-center rounded-custom overflow-hidden pt-5 px-3 px-lg-5 mx-lg-auto"
            >
              <div class="mb-5">
                <h3 class="fw-medium h4">
                  Best options to choose a Quiety, to make your own app and grow
                  your business
                </h3>
              </div>
              <div class="position-relative w-75 mx-auto">
                <img
                  class="position-relative z-2 w-100 h-auto"
                  src="@/assets/img/screen/half-iphone.svg"
                  alt="Image Description"
                />
                <img
                  class="position-absolute half-screen"
                  src="@/assets/img/screen/app-screen-1.jpg"
                  alt="Image Description"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ImgContentFour',
};
</script>
