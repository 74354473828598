<template>
  <section class="feature-section ptb-120">
    <div class="container">
      <div class="row align-content-center justify-content-between">
        <div class="col-lg-5 col-md-12">
          <div class="section-heading mb-5">
            <div
              class="icon-box rounded-custom bg-danger shadow-sm d-inline-block"
            >
              <i class="fal fa-shield-check fa-2x text-white"></i>
            </div>
            <h2>Increasing Business Success With Technology</h2>
            <p>
              Uniquely benchmark multifunctional catalysts for change via
              manufactured products brand scalable products. Network orthogonal
              infomediaries through pandemic initiatives.
            </p>
          </div>
          <div class="single-feature d-flex mb-4">
            <span class="fad fa-bezier-curve fa-2x text-primary"></span>
            <div class="ms-4 mt-2">
              <h5>Pixel Perfect Design</h5>
              <p>
                Credibly syndicate enterprise total linkage whereas cost
                effective of the art data without multifunctional.
              </p>
            </div>
          </div>
          <div class="single-feature d-flex mb-4">
            <span class="fad fa-code fa-2x text-primary"></span>
            <div class="ms-4 mt-2">
              <h5>Development Execution</h5>
              <p>
                Synergistically communicate excellent rather than
                enterprise-wide value quickly architect emerging interfaces.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-8">
          <div class="feature-shape-img position-relative mt-5 mt-lg-0">
            <div class="img-bg-shape position-absolute">
              <img src="@/assets/img/integations/shape.svg" alt="integations" />
            </div>
            <img
              src="@/assets/img/dashkit.png"
              class="img-fluid skewed-img-right rounded-custom shadow-lg"
              alt="softtent"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ImgContentSeven',
};
</script>
