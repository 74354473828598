<template>
  <section class="work-process ptb-120">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-10">
          <div class="section-heading text-center">
            <h4 class="h5 text-primary">Process</h4>
            <h2>We Follow Our Work Process</h2>
            <p>
              Enthusiastically engage cross-media leadership skills for
              alternative experiences. Proactively drive vertical systems than
              intuitive architectures.
            </p>
          </div>
        </div>
      </div>
      <div class="row align-items-center justify-content-between">
        <div class="col-lg-5 col-md-12 order-1 order-lg-0">
          <div class="img-wrap">
            <img
              src="@/assets/img/office-img-1.jpg"
              alt="work process"
              class="img-fluid rounded-custom"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-12 order-0 order-lg-1">
          <ul class="work-process-list list-unstyled">
            <li class="d-flex align-items-start mb-4">
              <div
                class="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2"
              >
                <i class="far fa-folder-tree fa-2x"></i>
              </div>
              <div class="icon-content">
                <span class="text-primary h6">Step 1</span>
                <h3 class="h5 mb-2">Research and Content Planing</h3>
                <p>
                  Progressively foster enterprise-wide systems whereas equity
                  invested web-readiness harness installed base bandwidth.
                </p>
              </div>
            </li>
            <li class="d-flex align-items-start mb-4">
              <div
                class="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2"
              >
                <i class="far fa-bezier-curve fa-2x"></i>
              </div>
              <div class="icon-content">
                <span class="text-primary h6">Step 2</span>
                <h3 class="h5 mb-2">Publishing and Execution</h3>
                <p>
                  Dramatically administrate progressive metrics without
                  error-free globally simplify standardized alignments
                  plagiarize distributed.
                </p>
              </div>
            </li>
            <li class="d-flex align-items-start mb-4">
              <div
                class="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2"
              >
                <i class="far fa-layer-group fa-2x"></i>
              </div>
              <div class="icon-content">
                <span class="text-primary h6">Step 3</span>
                <h3 class="h5 mb-2">Product Prototyping</h3>
                <p>
                  Interactively whiteboard transparent testing procedures before
                  bricks-and-clicks initiatives administrate competencies.
                </p>
              </div>
            </li>
            <li class="d-flex align-items-start mb-4 mb-lg-0">
              <div
                class="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2"
              >
                <i class="far fa-truck fa-2x"></i>
              </div>
              <div class="icon-content">
                <span class="text-primary h6">Step 4</span>
                <h3 class="h5 mb-2">Deliver the Final Product</h3>
                <p>
                  Dramatically plagiarize distributed progressive metrics
                  without error-free globally simplify standardized alignments.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WorkProcess',
};
</script>

<style></style>
