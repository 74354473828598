<template>
  <div class="main">
    <LoginForm />
  </div>
</template>

<script>
import LoginForm from "../../components/other's/LoginForm.vue";
export default {
  name: 'Login',
  components: { LoginForm },
};
</script>
