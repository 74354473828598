<template>
  <section class="office-address-section ptb-120">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-12">
          <div class="section-heading text-center">
            <h4 class="h5 text-primary">Our Office</h4>
            <h2>Located Around the World</h2>
            <p>
              Dynamically technically sound technologies with parallel task
              convergence quality vectors through excellent relationships.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-6 mt-4 mt-lg-0 mt-xl-0">
          <div
            class="rounded-custom border d-block office-address overflow-hidden z-2 office-one"
          >
            <div class="office-content text-center p-4">
              <span class="office-overlay"></span>
              <div class="office-info">
                <h5>Singapore</h5>
                <address>
                  4636 Bombardier Way <br />
                  Sr,Worthington, <br />California(CA), 94102
                </address>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 mt-4 mt-lg-0 mt-xl-0">
          <div
            class="rounded-custom border d-block office-address overflow-hidden z-2 office-two"
          >
            <div class="office-content text-center p-4">
              <span class="office-overlay"></span>
              <div class="office-info">
                <h5>Los Angeles</h5>
                <address>
                  794 Mcallister <br />
                  St. Oak Harbor, <br />Washington(WA), 98277
                </address>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 mt-4 mt-lg-0 mt-xl-0">
          <div
            class="rounded-custom border d-block office-address overflow-hidden z-2 office-three"
          >
            <div class="office-content text-center p-4">
              <span class="office-overlay"></span>
              <div class="office-info">
                <h5>New York</h5>
                <address>
                  4219 Snowbird Lane <br />
                  St Carthage, <br />New York(NY), 13619
                </address>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 mt-4 mt-lg-0 mt-xl-0">
          <div
            class="rounded-custom border d-block office-address overflow-hidden z-2 office-four"
          >
            <div class="office-content text-center p-4">
              <span class="office-overlay"></span>
              <div class="office-info">
                <h5>Barlin City</h5>
                <address>
                  Brandenburgische Straße <br />
                  DE. Berlin Kreuzberg, <br />Berlin(CA), 10997
                </address>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "OurOffice",
};
</script>

<style scoped>
.office-one {
  background-image: url("../../assets/img/office-img-1.jpg");
  background-position: center;
  background-size: cover;
}
.office-two {
  background-image: url("../../assets/img/office-img-2.jpg");
  background-position: center;
  background-size: cover;
}
.office-three {
  background-image: url("../../assets/img/office-img-3.jpg");
  background-position: center;
  background-size: cover;
}
.office-four {
  background-image: url("../../assets/img/office-img-5.jpg");
  background-position: center;
  background-size: cover;
}
</style>
