<template>
  <section class="pricing-section ptb-120 position-relative z-2">
    <div class="container">
      <div class="" :class="hasTitle ? 'row justify-content-center' : 'd-none'">
        <div class="col-lg-6 col-md-10">
          <div class="section-heading text-center">
            <h4 class="h5 text-primary">Pricing</h4>
            <h2>Check Our Valuable Price</h2>
            <p>
              Conveniently mesh cooperative services via magnetic outsourcing.
              Dynamically grow value whereas accurate e-commerce vectors.
            </p>
          </div>
        </div>
      </div>
      <div
        class="mb-5"
        :class="hasTitle ? 'row justify-content-center' : 'd-none'"
      >
        <div class="col-lg-3">
          <div class="media d-flex align-items-center py-2 p-sm-2">
            <div
              class="icon-box mb-0 bg-primary-soft rounded-circle d-block me-3"
            >
              <i class="fal fa-credit-card text-primary"></i>
            </div>
            <div class="media-body fw-medium h6 mb-0">
              No credit card required
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="media d-flex align-items-center py-2 p-sm-2">
            <div
              class="icon-box mb-0 bg-success-soft rounded-circle d-block me-3"
            >
              <i class="fal fa-calendar-check text-success"></i>
            </div>
            <div class="media-body fw-medium h6 mb-0">
              Get 30 day free trial
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="media d-flex align-items-center py-2 p-sm-2">
            <div
              class="icon-box mb-0 bg-danger-soft rounded-circle d-block me-3"
            >
              <i class="fal fa-calendar-times text-danger"></i>
            </div>
            <div class="media-body fw-medium h6 mb-0">Cancel anytime</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div
            class="position-relative single-pricing-wrap rounded-custom bg-white custom-shadow p-5 mb-4 mb-lg-0"
          >
            <div class="pricing-header mb-32">
              <h3 class="package-name text-primary d-block">Stater</h3>
              <h4 class="display-6 fw-semi-bold">$25<span>/month</span></h4>
            </div>
            <div class="pricing-info mb-4">
              <ul class="pricing-feature-list list-unstyled">
                <li>
                  <i class="fas fa-circle fa-2xs text-primary me-2"></i> 1 Team
                </li>
                <li>
                  <i class="fas fa-circle fa-2xs text-primary me-2"></i> 1
                  Installed Agent
                </li>
                <li>
                  <i class="fas fa-circle fa-2xs text-primary me-2"></i>
                  Real-Time Feedback
                </li>
                <li>
                  <i class="fas fa-circle fa-2xs text-primary me-2"></i> Video
                  Dedicated Support
                </li>
                <li>
                  <i class="fas fa-circle fa-2xs text-primary me-2"></i> 1
                  Attacked Targets Per Month
                </li>
                <li>
                  <i class="fas fa-circle fa-2xs text-primary me-2"></i> Team
                  Collaboration Tools
                </li>
                <li>
                  <i class="fas fa-circle fa-2xs text-primary me-2"></i>
                  Automated Updated Features
                </li>
                <li>
                  <i class="fas fa-circle fa-2xs text-primary me-2"></i> 24/7
                  Life time Support
                </li>
              </ul>
            </div>
            <router-link
              router-link
              to="/request-for-demo"
              class="btn btn-outline-primary mt-2"
              >Buy Now</router-link
            >

            <!--pattern start-->
            <div
              class="dot-shape-bg position-absolute z--1 left--40 bottom--40"
            >
              <img src="@/assets/img/shape/dot-big-square.svg" alt="shape" />
            </div>
            <!--pattern end-->
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div
            class="position-relative single-pricing-wrap rounded-custom bg-gradient text-white p-5 mb-4 mb-lg-0"
          >
            <div class="pricing-header mb-32">
              <h3 class="package-name text-warning d-block">Advanced</h3>
              <h4 class="display-6 fw-semi-bold">$45<span>/month</span></h4>
            </div>
            <div class="pricing-info mb-4">
              <ul class="pricing-feature-list list-unstyled">
                <li>
                  <i class="fas fa-circle fa-2xs text-warning me-2"></i> 5 Team
                </li>
                <li>
                  <i class="fas fa-circle fa-2xs text-warning me-2"></i> 3
                  Installed Agent
                </li>
                <li>
                  <i class="fas fa-circle fa-2xs text-warning me-2"></i>
                  Real-Time Feedback
                </li>
                <li>
                  <i class="fas fa-circle fa-2xs text-warning me-2"></i> Video
                  Dedicated Support
                </li>
                <li>
                  <i class="fas fa-circle fa-2xs text-warning me-2"></i> 24
                  Attacked Targets Per Month
                </li>
                <li>
                  <i class="fas fa-circle fa-2xs text-warning me-2"></i> Team
                  Collaboration Tools
                </li>
                <li>
                  <i class="fas fa-circle fa-2xs text-warning me-2"></i>
                  Automated Updated Features
                </li>
                <li>
                  <i class="fas fa-circle fa-2xs text-warning me-2"></i> 24/7
                  Life time Support
                </li>
              </ul>
            </div>
            <router-link to="/request-for-demo" class="btn btn-primary mt-2"
              >Buy Now</router-link
            >
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div
            class="position-relative single-pricing-wrap rounded-custom bg-white custom-shadow p-5 mb-4 mb-lg-0"
          >
            <div class="pricing-header mb-32">
              <h3 class="package-name text-primary d-block">Premium</h3>
              <h4 class="display-6 fw-semi-bold">$75<span>/month</span></h4>
            </div>
            <div class="pricing-info mb-4">
              <ul class="pricing-feature-list list-unstyled">
                <li>
                  <i class="fas fa-circle fa-2xs text-primary me-2"></i> 6 Team
                </li>
                <li>
                  <i class="fas fa-circle fa-2xs text-primary me-2"></i> 8
                  Installed Agent
                </li>
                <li>
                  <i class="fas fa-circle fa-2xs text-primary me-2"></i>
                  Real-Time Feedback
                </li>
                <li>
                  <i class="fas fa-circle fa-2xs text-primary me-2"></i> Video
                  Dedicated Support
                </li>
                <li>
                  <i class="fas fa-circle fa-2xs text-primary me-2"></i> 40
                  Attacked Targets Per Month
                </li>
                <li>
                  <i class="fas fa-circle fa-2xs text-primary me-2"></i> Team
                  Collaboration Tools
                </li>
                <li>
                  <i class="fas fa-circle fa-2xs text-primary me-2"></i>
                  Automated Updated Features
                </li>
                <li>
                  <i class="fas fa-circle fa-2xs text-primary me-2"></i> 24/7
                  Life time Support
                </li>
              </ul>
            </div>
            <router-link
              to="/request-for-demo"
              class="btn btn-outline-primary mt-2"
              >Buy Now</router-link
            >

            <!--pattern start-->
            <div class="dot-shape-bg position-absolute z--1 right--40 top--40">
              <img src="@/assets/img/shape/dot-big-square.svg" alt="shape" />
            </div>
            <!--pattern end-->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Price",
  props: {
    hasTitle: String,
  },
  data() {
    return {};
  },
};
</script>

<style></style>
