<template>
  <section
    class="page-header position-relative overflow-hidden ptb-120 bg-dark"
  >
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-lg-8 col-12">
          <div class="company-info-wrap align-items-center">
            <div
              class="company-logo p-4 bg-white shadow rounded-custom me-4 mt-2"
            >
              <div class="logo justify-content-center">
                <img
                  src="@/assets/img/integations/2.png"
                  alt="company logo"
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="company-overview">
              <h1 class="display-5 fw-bold">Connect with Google</h1>
              <p class="lead mb-0">
                Objectively fabricate strategic products for high-impact
                materials.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-12">
          <div class="action-btns mt-4 mt-lg-0 mt-xl-0">
            <a href="javascript:;" class="btn btn-outline-light"
              >Connect with Google</a
            >
          </div>
        </div>
      </div>

      <div
        class="bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light right-5"
      ></div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
