<template>
  <div class="main-wrapper">
    <Recovery />
  </div>
</template>

<script>
import Recovery from "../../components/other's/Recovery.vue";
export default {
  components: {
    Recovery,
  },
};
</script>

Recovery
