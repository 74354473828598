<template>
  <section class="promo-section ptb-120" :class="[bgLight ? 'bg-light' : '']">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-6">
          <div class="section-heading text-center" data-aos="fade-up">
            <h2>Everything We Do it With Love</h2>
            <p>
              Progressively deploy market positioning catalysts for change and
              technically sound authoritatively e-enable resource-leveling
              infrastructures.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-lg-4">
          <div
            class="promo-card-wrap promo-border-hover border border-2 bg-white rounded-custom p-5 mb-4 mb-lg-0 mb-md-4"
            data-aos="fade-up"
            data-aos-delay="50"
          >
            <div class="promo-card-info">
              <h3 class="display-5 fw-medium mb-4">150+</h3>
              <h2 class="h5">Completed Projects</h2>
              <p class="mb-0">
                Rapidiously embrace distinctive best practices after B2B
                syndicate.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div
            class="promo-card-wrap promo-border-hover border border-2 bg-white rounded-custom p-5 mb-4 mb-lg-0 mb-md-4"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div class="promo-card-info">
              <h3 class="display-5 fw-medium mb-4">1.5k</h3>
              <h2 class="h5">Happy Client</h2>
              <p class="mb-0">
                Energistically impact synergistic convergence for leadership..
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div
            class="promo-card-wrap promo-border-hover border border-2 bg-white rounded-custom p-5 mb-4 mb-lg-0 mb-md-0"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            <div class="promo-card-info">
              <h3 class="display-5 fw-medium mb-4">90%</h3>
              <h2 class="h5">24/7 Support</h2>
              <p class="mb-0">
                Synergistically revolutionize leadership whereas platform.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PromoFour",
  props: {
    bgLight: String,
  },
};
</script>
