var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"price-two ptb-120"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"position-relative single-pricing-wrap rounded-custom bg-white custom-shadow p-5 mb-4 mb-lg-0"},[_vm._m(1),_vm._m(2),_c('router-link',{staticClass:"btn btn-outline-primary mt-2",attrs:{"to":"/request-for-demo"}},[_vm._v("Get Started Now")])],1)]),_c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"position-relative single-pricing-wrap rounded-custom bg-gradient text-white p-5 mb-4 mb-lg-0"},[_vm._m(3),_vm._m(4),_c('router-link',{staticClass:"btn btn-primary mt-2",attrs:{"to":"/request-for-demo"}},[_vm._v("Get Started Now")])],1)]),_c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"position-relative single-pricing-wrap rounded-custom bg-white custom-shadow p-5 mb-4 mb-lg-0"},[_vm._m(5),_vm._m(6),_c('router-link',{staticClass:"btn btn-outline-primary mt-2",attrs:{"to":"/request-for-demo"}},[_vm._v("Get Started Now")])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-6 col-md-10"},[_c('div',{staticClass:"section-heading text-center"},[_c('h2',[_vm._v(" Get our any Service "),_c('br'),_vm._v(" With Suitable Pricing ")]),_c('p',[_vm._v(" Credibly grow premier ideas rather than bricks-and-clicks strategic theme areas distributed for stand-alone web-readiness. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricing-header mb-32"},[_c('h3',{staticClass:"package-name text-primary d-block"},[_vm._v("Stater")]),_c('h4',{staticClass:"display-6 fw-semi-bold"},[_vm._v("$29"),_c('span',[_vm._v("/month")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricing-info mb-4"},[_c('ul',{staticClass:"pricing-feature-list list-unstyled"},[_c('li',[_c('i',{staticClass:"far fa-check-circle text-success me-2"}),_vm._v("Create Unique Websites ")]),_c('li',[_c('i',{staticClass:"far fa-check-circle text-success me-2"}),_vm._v(" Automate Your Busy Work ")]),_c('li',[_c('i',{staticClass:"far fa-times-circle text-muted me-2"}),_vm._v(" Optimize All Your Efforts ")]),_c('li',[_c('i',{staticClass:"far fa-times-circle text-muted me-2"}),_vm._v(" Centralized Teams ")]),_c('li',[_c('i',{staticClass:"far fa-times-circle text-muted me-2"}),_vm._v(" Shareable Team Libraries ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricing-header mb-32"},[_c('h3',{staticClass:"package-name text-warning d-block"},[_vm._v("Advanced")]),_c('h4',{staticClass:"display-6 fw-semi-bold"},[_vm._v("$49"),_c('span',[_vm._v("/month")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricing-info mb-4"},[_c('ul',{staticClass:"pricing-feature-list list-unstyled"},[_c('li',[_c('i',{staticClass:"far fa-check-circle text-success me-2"}),_vm._v("Create Unique Websites ")]),_c('li',[_c('i',{staticClass:"far fa-check-circle text-success me-2"}),_vm._v(" Automate Your Busy Work ")]),_c('li',[_c('i',{staticClass:"far fa-check-circle text-success me-2"}),_vm._v(" Optimize All Your Efforts ")]),_c('li',[_c('i',{staticClass:"far fa-times-circle text-muted me-2"}),_vm._v(" Centralized Teams ")]),_c('li',[_c('i',{staticClass:"far fa-times-circle text-muted me-2"}),_vm._v(" Shareable Team Libraries ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricing-header mb-32"},[_c('h3',{staticClass:"package-name text-primary d-block"},[_vm._v("Premium")]),_c('h4',{staticClass:"display-6 fw-semi-bold"},[_vm._v("$69"),_c('span',[_vm._v("/month")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricing-info mb-4"},[_c('ul',{staticClass:"pricing-feature-list list-unstyled"},[_c('li',[_c('i',{staticClass:"far fa-check-circle text-success me-2"}),_vm._v(" Create Unique Websites ")]),_c('li',[_c('i',{staticClass:"far fa-check-circle text-success me-2"}),_vm._v(" Automate Your Busy Work ")]),_c('li',[_c('i',{staticClass:"far fa-check-circle text-success me-2"}),_vm._v(" Optimize All Your Efforts ")]),_c('li',[_c('i',{staticClass:"far fa-check-circle text-success me-2"}),_vm._v(" Video Centralized Teams ")]),_c('li',[_c('i',{staticClass:"far fa-check-circle text-success me-2"}),_vm._v(" 40 Shareable Team Libraries ")])])])
}]

export { render, staticRenderFns }