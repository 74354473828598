<template>
  <section class="payment-news-letter pt-60 pb-120">
    <div class="container">
      <div class="row ptb-120 align-items-center">
        <div class="col-lg-5">
          <div class="mb-5 me-3 mb-lg-0">
            <h2 class="mb-4">Get Mobile App & Make Life more Easy</h2>
            <p>
              Globally expedite sticky platforms whereas end-to-end vortals.
              Energistically synergize emerging . Monotonectally incubate
              bleeding-edge e-business Appropriately matrix quality core
              competencies via cross-media functionalities.
            </p>
            <div class="payment-store-btn mt-4">
              <ul class="list-unstyled m-0">
                <li class="d-inline-block me-2 mb-3 mb-md-0">
                  <a
                    class="d-flex align-items-center text-decoration-none rounded shadow-lg"
                    href="#"
                    ><i class="fab fa-google-play pe-2"></i>
                    <span
                      >Get it on
                      <span class="d-block fw-semi-bold"
                        >Google Play</span
                      ></span
                    ></a
                  >
                </li>
                <li class="d-inline-block">
                  <a
                    class="d-flex align-items-center text-decoration-none rounded shadow-lg"
                    href="#"
                    ><i class="fab fa-apple pe-2"></i>
                    <span>
                      Downlad on the<span class="d-block fw-medium"
                        >App Store
                      </span></span
                    ></a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="p-cta-right position-relative mt-5 mt-lg-0">
            <div class="p-cta-img text-center position-relative">
              <img src="@/assets/img/p-mock.png" alt="Mockup" />
            </div>
            <ul class="payment-cta-shape list-unstyled">
              <li>
                <img
                  src="@/assets/img/ps-1.png"
                  class="shadow-md"
                  alt="Shape"
                />
              </li>
              <li><img src="@/assets/img/ps-2.png" alt="Shape" /></li>
              <li>
                <img
                  src="@/assets/img/ps-3.png"
                  class="shadow-lg rounded-circle"
                  alt="Shape"
                />
              </li>
              <li>
                <img
                  src="@/assets/img/ps-4.png"
                  class="rouned-circle shadow-md"
                  alt="Shape"
                />
              </li>
              <li><img src="@/assets/img/ps-5.png" alt="Shape" /></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="text-center">
            <h3>Start Trading on Quiety</h3>
            <p class="">
              Collaboratively extend state of the art products for
              functionalized action items. Credibly orchestrate user friendly
              resources.
            </p>
            <form action="">
              <div class="payment-email-form d-flex position-relative">
                <input
                  type="text"
                  class="mail-input form-control shadow-none"
                  placeholder="Enter your email"
                />
                <button class="payment-btn position-absolute end-0">
                  Subscribe Now
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
