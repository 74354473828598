<template>
  <section class="app-two-cta app-two-cta-bg ptb-120 text-white">
    <div class="container">
      <div class="row align-items-center justify-content-lg-between">
        <div class="col-lg-6 col-xl-6">
          <div class="app-two-mockup position-relative text-center pe-5">
            <img
              src="@/assets/img/app-two-mockup.png"
              alt=""
              class="img-fluid position-relative z-5"
            />
          </div>
        </div>
        <div class="col-lg-6 col-xl-6 col-md-10">
          <div class="app-two-cta-right px-md-0 pt-5 pt-md-0">
            <div class="section-heading text-white">
              <h2 class="text-white">
                Powerful Solutions for Your Business Needs
              </h2>
              <p>
                Quickly incubate functional channels with multidisciplinary
                architectures authoritatively fabricate.
              </p>
            </div>
            <div class="cta-count">
              <ul class="list-unstyled d-flex">
                <li class="me-4">
                  <h3 class="text-white mb-0">72k+</h3>
                  <span>Happy users</span>
                </li>
                <li class="me-4">
                  <h3 class="text-white mb-0">16</h3>
                  <span>Good reviews</span>
                </li>
                <li class="me-4">
                  <h3 class="text-white mb-0">28k+</h3>
                  <span>App download</span>
                </li>
              </ul>
            </div>
            <div class="action-btns mt-5">
              <router-link to="/contact" class="btn btn-outline-light"
                >Learn More</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
