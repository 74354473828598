<template>
  <section
    class="customer-review-tab ptb-120 position-relative z-2"
    :class="bgGradient ? 'bg-gradient text-white' : 'bg-light'"
  >
    <div class="container">
      <div class="row justify-content-center align-content-center">
        <div class="col-md-10 col-lg-6">
          <div class="section-heading text-center">
            <h4
              class="h5"
              :class="bgGradient ? 'text-warning' : 'text-primary'"
            >
              Testimonial
            </h4>
            <h2>What They Say About Us</h2>
            <p>
              Uniquely promote adaptive quality vectors rather than stand-alone
              e-markets. pontificate alternative architectures whereas iterate.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="tab-content" id="testimonial-tabContent">
            <div
              class="tab-pane fade active show"
              id="testimonial-tab-1"
              role="tabpanel"
            >
              <div class="row align-items-center justify-content-between">
                <div class="col-lg-6 col-md-6">
                  <div class="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                    <img
                      src="@/assets/img/testimonial/quotes-left.svg"
                      alt="testimonial quote"
                      width="65"
                      class="img-fluid mb-32"
                    />
                    <div class="blockquote-title-review mb-4">
                      <h3 class="mb-0 h4 fw-semi-bold">
                        The Best Template You Got to Have it!
                      </h3>
                      <ul class="review-rate mb-0 list-unstyled list-inline">
                        <li class="list-inline-item">
                          <i class="fas fa-star text-warning"></i>
                        </li>
                        <li class="list-inline-item">
                          <i class="fas fa-star text-warning"></i>
                        </li>
                        <li class="list-inline-item">
                          <i class="fas fa-star text-warning"></i>
                        </li>
                        <li class="list-inline-item">
                          <i class="fas fa-star text-warning"></i>
                        </li>
                        <li class="list-inline-item">
                          <i class="fas fa-star text-warning"></i>
                        </li>
                      </ul>
                    </div>

                    <blockquote class="blockquote">
                      <p>
                        Globally network long-term high-impact schemas vis-a-vis
                        distinctive e-commerce cross-media infrastructures
                        rather than ethical sticky alignments rather than
                        global. Plagiarize technically sound total linkage for
                        leveraged value media web-readiness and premium
                        processes.
                      </p>
                    </blockquote>
                    <div class="author-info mt-4">
                      <h6 class="mb-0">Joe Richard</h6>
                      <span>Visual Designer</span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-5 col-md-6">
                  <div class="author-img-wrap pt-5 ps-5">
                    <div class="testimonial-video-wrapper position-relative">
                      <img
                        src="@/assets/img/testimonial/t-1.jpg"
                        class="img-fluid rounded-custom shadow-lg"
                        alt="testimonial author"
                      />
                      <div
                        class="customer-info text-white d-flex align-items-center"
                      >
                        <!-- <modal-three /> -->
                      </div>
                      <div
                        class="position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="testimonial-tab-2" role="tabpanel">
              <div class="row align-items-center justify-content-between">
                <div class="col-lg-6 col-md-6">
                  <div class="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                    <img
                      src="@/assets/img/testimonial/quotes-left.svg"
                      alt="testimonial quote"
                      width="65"
                      class="img-fluid mb-32"
                    />
                    <div class="blockquote-title-review mb-4">
                      <h3 class="mb-0 h4 fw-semi-bold">
                        Embarrassed by the First Version.
                      </h3>
                      <ul class="review-rate mb-0 list-unstyled list-inline">
                        <li class="list-inline-item">
                          <i class="fas fa-star text-warning"></i>
                        </li>
                        <li class="list-inline-item">
                          <i class="fas fa-star text-warning"></i>
                        </li>
                        <li class="list-inline-item">
                          <i class="fas fa-star text-warning"></i>
                        </li>
                        <li class="list-inline-item">
                          <i class="fas fa-star text-warning"></i>
                        </li>
                        <li class="list-inline-item">
                          <i class="fas fa-star text-warning"></i>
                        </li>
                      </ul>
                    </div>
                    <blockquote class="blockquote">
                      <p>
                        Energistically streamline robust architectures whereas
                        distributed mindshare. Intrinsicly leverage other's
                        backend growth strategies through 24/365 products.
                        Conveniently pursue revolutionary communities for
                        compelling process improvements.
                      </p>
                    </blockquote>
                    <div class="author-info mt-4">
                      <h6 class="mb-0">Rupan Oberoi</h6>
                      <span>Web Designer</span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-5 col-md-6">
                  <div class="author-img-wrap pt-5 ps-5">
                    <div class="testimonial-video-wrapper position-relative">
                      <img
                        src="@/assets/img/testimonial/t-2.jpg"
                        class="img-fluid rounded-custom shadow-lg"
                        alt="testimonial author"
                      />
                      <div
                        class="customer-info text-white d-flex align-items-center"
                      >
                        <!-- <modal-three /> -->
                      </div>
                      <div
                        class="position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="testimonial-tab-3" role="tabpanel">
              <div class="row align-items-center justify-content-between">
                <div class="col-lg-6 col-md-6">
                  <div class="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                    <img
                      src="@/assets/img/testimonial/quotes-left.svg"
                      alt="testimonial quote"
                      width="65"
                      class="img-fluid mb-32"
                    />
                    <div class="blockquote-title-review mb-4">
                      <h3 class="mb-0 h4 fw-semi-bold">
                        Amazing Quiety template!
                      </h3>
                      <ul class="review-rate mb-0 list-unstyled list-inline">
                        <li class="list-inline-item">
                          <i class="fas fa-star text-warning"></i>
                        </li>
                        <li class="list-inline-item">
                          <i class="fas fa-star text-warning"></i>
                        </li>
                        <li class="list-inline-item">
                          <i class="fas fa-star text-warning"></i>
                        </li>
                        <li class="list-inline-item">
                          <i class="fas fa-star text-warning"></i>
                        </li>
                        <li class="list-inline-item">
                          <i class="fas fa-star text-warning"></i>
                        </li>
                      </ul>
                    </div>
                    <blockquote class="blockquote">
                      <p>
                        Appropriately negotiate interactive niches rather
                        orchestrate scalable benefits whereas flexible systems.
                        Objectively grow quality outsourcing without vertical
                        methods of empowerment. Assertively negotiate just in
                        time innovation after client-centered thinking.
                      </p>
                    </blockquote>
                    <div class="author-info mt-4">
                      <h6 class="mb-0">Jon Doe</h6>
                      <span>Software Engineer</span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-5 col-md-6">
                  <div class="author-img-wrap pt-5 ps-5">
                    <div class="testimonial-video-wrapper position-relative">
                      <img
                        src="@/assets/img/testimonial/t-3.jpg"
                        class="img-fluid rounded-custom shadow-lg"
                        alt="testimonial author"
                      />
                      <div
                        class="customer-info text-white d-flex align-items-center"
                      ></div>
                      <div
                        class="position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="testimonial-tab-4" role="tabpanel">
              <div class="row align-items-center justify-content-between">
                <div class="col-lg-6 col-md-6">
                  <div class="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                    <img
                      src="@/assets/img/testimonial/quotes-left.svg"
                      alt="testimonial quote"
                      width="65"
                      class="img-fluid mb-32"
                    />
                    <div class="blockquote-title-review mb-4">
                      <h3 class="mb-0 h4 fw-semi-bold">
                        Best Template for SAAS Company!
                      </h3>
                      <ul class="review-rate mb-0 list-unstyled list-inline">
                        <li class="list-inline-item">
                          <i class="fas fa-star text-warning"></i>
                        </li>
                        <li class="list-inline-item">
                          <i class="fas fa-star text-warning"></i>
                        </li>
                        <li class="list-inline-item">
                          <i class="fas fa-star text-warning"></i>
                        </li>
                        <li class="list-inline-item">
                          <i class="fas fa-star text-warning"></i>
                        </li>
                        <li class="list-inline-item">
                          <i class="fas fa-star text-warning"></i>
                        </li>
                      </ul>
                    </div>
                    <blockquote class="blockquote">
                      <p>
                        Competently repurpose cost effective strategic theme
                        areas and customer directed meta-services. Objectively
                        orchestrate orthogonal initiatives after enterprise-wide
                        bandwidth. Dynamically generate extensive through
                        cooperative channels time partnerships.
                      </p>
                    </blockquote>
                    <div class="author-info mt-4">
                      <h6 class="mb-0">Hanry Luice</h6>
                      <span>App Developer</span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-5 col-md-6">
                  <div class="author-img-wrap pt-5 ps-5">
                    <div class="testimonial-video-wrapper position-relative">
                      <img
                        src="@/assets/img/testimonial/t-4.jpg"
                        class="img-fluid rounded-custom shadow-lg"
                        alt="testimonial author"
                      />
                      <div
                        class="customer-info text-white d-flex align-items-center"
                      >
                        <!-- <modal-three /> -->
                      </div>
                      <div
                        class="position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="testimonial-tab-5" role="tabpanel">
              <div class="row align-items-center justify-content-between">
                <div class="col-lg-6 col-md-6">
                  <div class="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                    <img
                      src="@/assets/img/testimonial/quotes-left.svg"
                      alt="testimonial quote"
                      width="65"
                      class="img-fluid mb-32"
                    />
                    <div class="blockquote-title-review mb-4">
                      <h3 class="mb-0 h4 fw-semi-bold">
                        It is Undeniably Good!
                      </h3>
                      <ul class="review-rate mb-0 list-unstyled list-inline">
                        <li class="list-inline-item">
                          <i class="fas fa-star text-warning"></i>
                        </li>
                        <li class="list-inline-item">
                          <i class="fas fa-star text-warning"></i>
                        </li>
                        <li class="list-inline-item">
                          <i class="fas fa-star text-warning"></i>
                        </li>
                        <li class="list-inline-item">
                          <i class="fas fa-star text-warning"></i>
                        </li>
                        <li class="list-inline-item">
                          <i class="fas fa-star text-warning"></i>
                        </li>
                      </ul>
                    </div>
                    <blockquote class="blockquote">
                      <p>
                        Appropriately disintermediate one-to-one vortals through
                        cross functional infomediaries. Collaboratively pursue
                        multidisciplinary systems through stand-alone
                        architectures. Progressively transition covalent
                        architectures whereas vertical applications
                        procrastinate professional.
                      </p>
                    </blockquote>
                    <div class="author-info mt-4">
                      <h6 class="mb-0">Ami Nijai</h6>
                      <span>Customer Support</span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-5 col-md-6">
                  <div class="author-img-wrap pt-5 ps-5">
                    <div class="testimonial-video-wrapper position-relative">
                      <img
                        src="@/assets/img/testimonial/t-5.jpg"
                        class="img-fluid rounded-custom shadow-lg"
                        alt="testimonial author"
                      />
                      <div
                        class="customer-info text-white d-flex align-items-center"
                      >
                        <!-- <modal-three /> -->
                      </div>
                      <div
                        class="position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <CoolLightBox
          :items="items"
          :index="index"
          @close="index = null"
          :closeOnClickOutsideMobile="true"
        >
        </CoolLightBox>
        <div class="col-12">
          <ul
            class="nav nav-pills testimonial-tab-menu mt-60"
            id="testimonial"
            role="tablist"
          >
            <li class="nav-item" role="presentation">
              <div
                class="nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link active"
                data-bs-toggle="pill"
                data-bs-target="#testimonial-tab-1"
                role="tab"
                aria-selected="false"
              >
                <div class="testimonial-thumb me-3">
                  <img
                    src="@/assets/img/testimonial/1.jpg"
                    width="50"
                    class="rounded-circle"
                    alt="testimonial thumb"
                  />
                </div>
                <div class="author-info">
                  <h6 class="mb-0">Joe Richard</h6>
                  <span>Visual Designer</span>
                </div>
              </div>
            </li>
            <li class="nav-item" role="presentation">
              <div
                class="nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link"
                data-bs-toggle="pill"
                data-bs-target="#testimonial-tab-2"
                role="tab"
                aria-selected="false"
              >
                <div class="testimonial-thumb me-3">
                  <img
                    src="@/assets/img/testimonial/2.jpg"
                    width="50"
                    class="rounded-circle"
                    alt="testimonial thumb"
                  />
                </div>
                <div class="author-info">
                  <h6 class="mb-0">Rupan Oberoi</h6>
                  <span>Web Designer</span>
                </div>
              </div>
            </li>
            <li class="nav-item" role="presentation">
              <div
                class="nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link"
                data-bs-toggle="pill"
                data-bs-target="#testimonial-tab-3"
                role="tab"
                aria-selected="false"
              >
                <div class="testimonial-thumb me-3">
                  <img
                    src="@/assets/img/testimonial/3.jpg"
                    width="50"
                    class="rounded-circle"
                    alt="testimonial thumb"
                  />
                </div>
                <div class="author-info">
                  <h6 class="mb-0">Jon Doe</h6>
                  <span>Software Engineer</span>
                </div>
              </div>
            </li>
            <li class="nav-item" role="presentation">
              <div
                class="nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link"
                data-bs-toggle="pill"
                data-bs-target="#testimonial-tab-4"
                role="tab"
                aria-selected="false"
              >
                <div class="testimonial-thumb me-3">
                  <img
                    src="@/assets/img/testimonial/4.jpg"
                    width="50"
                    class="rounded-circle"
                    alt="testimonial thumb"
                  />
                </div>
                <div class="author-info">
                  <h6 class="mb-0">Hanry Luice</h6>
                  <span>App Developer</span>
                </div>
              </div>
            </li>
            <li class="nav-item" role="presentation">
              <div
                class="nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link"
                data-bs-toggle="pill"
                data-bs-target="#testimonial-tab-5"
                role="tab"
                aria-selected="true"
              >
                <div class="testimonial-thumb me-3">
                  <img
                    src="@/assets/img/testimonial/5.jpg"
                    width="50"
                    class="rounded-circle"
                    alt="testimonial thumb"
                  />
                </div>
                <div class="author-info">
                  <h6 class="mb-0">Ami Nijai</h6>
                  <span>Customer Support</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Testimonial",
  props: {
    bgGradient: String,
  },
  data: function () {
    return {
      items: [
        {
          src: "https://www.youtube.com/watch?v=hAP2QF--2Dg",
        },
      ],
      index: null,
    };
  },
};
</script>
