<template>
  <section class="benifits-area bg-white-light pt-60 pb-120">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="benifits-left position-relative mb-5">
            <div>
              <img
                src="@/assets/img/smiling-girl.png"
                class="img-fluid text-center"
                alt="girl"
              />
            </div>
            <ul class="list-unstyled bi-right-shape">
              <li><img src="@/assets/img/bi-shape.png" alt="shape" /></li>
              <li>
                <img src="@/assets/img/bi-shape-2.png" alt="shape" />
              </li>
              <li>
                <img src="@/assets/img/bi-shape-3.png" alt="shape" />
              </li>
              <li>
                <img src="@/assets/img/bi-pink-dot.png" alt="shape" />
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="benifits-right mt-5 mt-lg-0">
            <div class="benifits-right-content">
              <h2 class="mb-3">
                One App with thousands of <span class="gr-text">Benefits</span>
              </h2>
              <p class="m-0">
                Globally expedite sticky platforms whereas end-to-end vortals.
                Energistically synergize emerging . Monotonectally incubate
                bleeding-edge e-business
              </p>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="single-benifit mb-4 mb-lg-0">
                  <div class="benifit-icon one">
                    <img src="@/assets/img/bi-1.png" alt="icon" />
                  </div>
                  <h5>Easy Interface Design</h5>
                  <p class="m-0">
                    Globally expedite stick Energisticall synergize emerging
                    generation go incubate bleeding-edge.
                  </p>
                  <router-link
                    to="/about-us"
                    class="link-with-icon text-decoration-none"
                    >Explore More <i class="far fa-arrow-right"></i>
                  </router-link>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="single-benifit">
                  <div class="benifit-icon two">
                    <img src="@/assets/img/bi-2.png" alt="icon" />
                  </div>
                  <h5>Easy Sign Up</h5>
                  <p class="m-0">
                    Globally expedite stick Energisticall synergize emerging
                    generation go incubate bleeding-edge.
                  </p>
                  <router-link
                    to="/about-us"
                    class="link-with-icon text-decoration-none"
                    >Explore More <i class="far fa-arrow-right"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
