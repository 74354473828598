<template>
  <section class="payment-counter payment-counter-bg ptb-120">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6 order-last order-md-first">
          <div class="card-shape position-relative">
            <div class="card-img mt-5 mt-lg-0">
              <img
                src="@/assets/img/cr-card.png"
                class="img-fluid"
                alt="card"
              />
            </div>
            <ul class="list-unstyled m-0">
              <li>
                <div class="counter-circle">
                  <h4 class="text-danger m-0">32k</h4>
                  <small>Daily Transaction</small>
                </div>
              </li>
              <li>
                <div class="counter-circle">
                  <h4 class="text-success m-0">2.3K</h4>
                  <small>Seller</small>
                </div>
              </li>
              <li>
                <div class="counter-circle">
                  <h4 class="text-warning m-0">12b+</h4>
                  <small>Success Payment</small>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="payment-counter-right">
            <h2 class="text-black mb-4">The Universal Way of Payments</h2>
            <div class="mb-3">
              <h5 class="mb-2 h6">Debit Card</h5>
              <p class="m-0">
                Energistically synergize emerging . Monotonectally incubate
                bleeding-edge e-business Appropriately matrix quality.
              </p>
            </div>
            <div class="">
              <h5 class="mb-2 h6">Mobile App Payment</h5>
              <p class="m-0">
                Energistically synergize emerging . Monotonectally incubate
                bleeding-edge e-business Appropriately matrix quality.
              </p>
            </div>
            <router-link to="/request-for-demo" class="btn-gradient-sqr mt-40">
              Get Started
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
