<template>
  <section
    class="pricing-section position-relative overflow-hidden bg-dark text-white pt-120"
    style="
      background: url('@/assets/img/page-header-bg.svg') no-repeat center center;
    "
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-12">
          <div
            class="section-heading text-center z-5 position-relative"
            data-aos="fade-up"
          >
            <h4 class="h5 text-warning">Plans &amp; Pricing</h4>
            <h2>Check Our Valuable Price</h2>
            <p>
              Objectively market-driven intellectual capital rather than
              covalent best practices facilitate strategic information before
              innovation.
            </p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10">
          <div
            class="pricing-content-wrap mb--100 bg-white rounded-custom shadow-lg border d-fle position-relative"
          >
            <div
              class="price-feature-col pricing-feature-info left-radius bg-primary-soft p-5"
            >
              <ul
                class="list-unstyled pricing-feature-list pricing-included-list mb-0"
                data-aos="fade-up"
              >
                <li class="py-1">
                  <i class="fas fa-check-circle text-primary me-2"></i>
                  Unlimited domains
                </li>
                <li class="py-1">
                  <i class="fas fa-check-circle text-primary me-2"></i>Unlimited
                  notifications
                </li>
                <li class="py-1">
                  <i class="fas fa-check-circle text-primary me-2"></i> 10,000
                  unique visitors
                </li>
                <li class="py-1">
                  <i class="fas fa-check-circle text-primary me-2"></i>
                  Conversion analytics
                </li>
                <li class="py-1">
                  <i class="fas fa-check-circle text-primary me-2"></i>
                  Conversion analytics
                </li>
                <li class="py-1">
                  <i class="fas fa-check-circle text-primary me-2"></i> Live
                  chat support
                </li>
                <li class="py-1">
                  <i class="fas fa-check-circle text-primary me-2"></i> Recent
                  activity notification
                </li>
                <li class="py-1">
                  <i class="fas fa-check-circle text-primary me-2"></i> Live
                  visitor count notification
                </li>
                <li class="py-1">
                  <i class="fas fa-check-circle text-primary me-2"></i> Send
                  data to analytics tools
                </li>
              </ul>
            </div>
            <div
              class="price-feature-col pricing-action-info p-5"
              data-aos="fade-up"
            >
              <ul
                class="nav nav-pills mb-4 pricing-tab-list"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item" role="presentation">
                  <button
                    class="active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Monthly
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                    class=""
                  >
                    Yearly
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade active show"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <h3 class="h5">Pro Monthly</h3>
                  <p>
                    Professionally integrate principle-centered intellectual
                    capital whereas equity.
                  </p>
                  <div class="pricing-price mt-5">
                    <h4 class="h1 fw-bold">$49 <span>/Month</span></h4>
                  </div>
                  <router-link
                    to="/request-for-demo"
                    class="btn btn-primary mt-3"
                    >Start 14-Days Trial</router-link
                  >
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <h3 class="h5">Pro Yearly</h3>
                  <p>
                    Uniquely engineer prospective alignments without
                    clicks-and-mortar innovation.
                  </p>
                  <div class="pricing-price mt-5">
                    <h4 class="h1 fw-bold">$89 <span>/Year</span></h4>
                  </div>
                  <router-link
                    to="request-for-demo"
                    class="btn btn-primary mt-3"
                    >Start 14-Days Trial</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="bg-circle rounded-circle circle-shape-1 position-absolute bg-warning left-5"
    ></div>
    <div class="white-space-100 bg-white w-100"></div>
  </section>
</template>

<script>
export default {
  name: "PriceTwo",
};
</script>
