<template>
  <section
    class="integration-section ptb-120"
    :class="bgLight ? 'bg-light' : ''"
  >
    <div class="container">
      <div class="row align-items-center justify-content-lg-between">
        <div class="col-lg-6 col-md-12">
          <div class="section-heading" data-aos="fade-up">
            <h4 class="h5 text-primary">Integration</h4>
            <h2>We Collaborate with Top Software Company</h2>
            <p>
              Dynamically pursue convergence rather than 24/7 process
              improvements develop end-to-end customer service action items.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="text-lg-end mb-5 mb-lg-0" data-aos="fade-up">
            <router-link to="/integrations" class="btn btn-primary"
              >View All Integrations</router-link
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="integration-wrapper position-relative w-100">
            <ul class="integration-list list-unstyled mb-0">
              <li data-aos="fade-up" data-aos-delay="50">
                <div class="single-integration">
                  <img
                    src="@/assets/img/integations/airbnb.png"
                    alt="integration"
                    class="img-fluid"
                  />
                  <h6 class="mb-0 mt-4">Brand Name</h6>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-delay="50">
                <div class="single-integration">
                  <img
                    src="@/assets/img/integations/figma.png"
                    alt="integration"
                    class="img-fluid"
                  />
                  <h6 class="mb-0 mt-4">Brand Name</h6>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-delay="50">
                <div class="single-integration">
                  <img
                    src="@/assets/img/integations/facebook.png"
                    alt="integration"
                    class="img-fluid"
                  />
                  <h6 class="mb-0 mt-4">Brand Name</h6>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-delay="50">
                <div class="single-integration">
                  <img
                    src="@/assets/img/integations/sales-force.png"
                    alt="integration"
                    class="img-fluid"
                  />
                  <h6 class="mb-0 mt-4">Brand Name</h6>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-delay="50">
                <div class="single-integration">
                  <img
                    src="@/assets/img/integations/atlassian.png"
                    alt="integration"
                    class="img-fluid"
                  />
                  <h6 class="mb-0 mt-4">Brand Name</h6>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-delay="50">
                <div class="single-integration">
                  <img
                    src="@/assets/img/integations/dropbox-2.png"
                    alt="integration"
                    class="img-fluid"
                  />
                  <h6 class="mb-0 mt-4">Brand Name</h6>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-delay="100">
                <div class="single-integration">
                  <img
                    src="@/assets/img/integations/dynamic-365.png"
                    alt="integration"
                    class="img-fluid"
                  />
                  <h6 class="mb-0 mt-4">Brand Name</h6>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-delay="100">
                <div class="single-integration">
                  <img
                    src="@/assets/img/integations/erecruiter.png"
                    alt="integration"
                    class="img-fluid"
                  />
                  <h6 class="mb-0 mt-4">Brand Name</h6>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-delay="100">
                <div class="single-integration">
                  <img
                    src="@/assets/img/integations/evernote.png"
                    alt="integration"
                    class="img-fluid"
                  />
                  <h6 class="mb-0 mt-4">Brand Name</h6>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-delay="100">
                <div class="single-integration">
                  <img
                    src="@/assets/img/integations/google-icon.png"
                    alt="integration"
                    class="img-fluid"
                  />
                  <h6 class="mb-0 mt-4">Brand Name</h6>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="100">
                <div class="single-integration">
                  <img
                    src="@/assets/img/integations/slack.png"
                    alt="integration"
                    class="img-fluid"
                  />
                  <h6 class="mb-0 mt-4">Brand Name</h6>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="100">
                <div class="single-integration">
                  <img
                    src="@/assets/img/integations/google-analytics.png"
                    alt="integration"
                    class="img-fluid"
                  />
                  <h6 class="mb-0 mt-4">Brand Name</h6>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="150">
                <div class="single-integration">
                  <img
                    src="@/assets/img/integations/google-drive.png"
                    alt="integration"
                    class="img-fluid"
                  />
                  <h6 class="mb-0 mt-4">Brand Name</h6>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="150">
                <div class="single-integration">
                  <img
                    src="@/assets/img/integations/hubspot.png"
                    alt="integration"
                    class="img-fluid"
                  />
                  <h6 class="mb-0 mt-4">Brand Name</h6>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="150">
                <div class="single-integration">
                  <img
                    src="@/assets/img/integations/instagram.png"
                    alt="integration"
                    class="img-fluid"
                  />
                  <h6 class="mb-0 mt-4">Brand Name</h6>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-delay="150">
                <div class="single-integration">
                  <img
                    src="@/assets/img/integations/linkedin.png"
                    alt="integration"
                    class="img-fluid"
                  />
                  <h6 class="mb-0 mt-4">Brand Name</h6>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-delay="150">
                <div class="single-integration">
                  <img
                    src="@/assets/img/integations/mailchimp.png"
                    alt="integration"
                    class="img-fluid"
                  />
                  <h6 class="mb-0 mt-4">Brand Name</h6>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-delay="150">
                <div class="single-integration">
                  <img
                    src="@/assets/img/integations/marekto.png"
                    alt="integration"
                    class="img-fluid"
                  />
                  <h6 class="mb-0 mt-4">Brand Name</h6>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "IntegrationTwo",

  props: {
    bgLight: String,
  },
};
</script>
