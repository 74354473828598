<template>
  <section class="why-choose-us pt-60 pb-120">
    <div class="container">
      <div
        class="row justify-content-lg-between justify-content-center align-items-center"
      >
        <div class="col-lg-5 col-md-7 order-1 order-lg-0">
          <div class="why-choose-img position-relative">
            <img
              src="@/assets/img/feature-hero-img-2.svg"
              class="img-fluid"
              alt="duel-phone"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-12 order-0 order-lg-1">
          <div class="why-choose-content">
            <div class="mb-32">
              <h2>We run all kinds of services in form of software</h2>
              <p>
                Conceptualize multimedia based ROI and wireless intellectual
                capital. Dramatically seize visionary technologies whereas
                interactive value.
              </p>
            </div>
            <ul class="list-unstyled d-flex flex-wrap list-two-col">
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>Thought
                leadership
              </li>
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>Personal
                branding
              </li>
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>Modernized
                prospecting
              </li>
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>Better win
                rates
              </li>
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>Showcasing
                success
              </li>
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>Sales
                compliance
              </li>
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>Manage
                smartly
              </li>
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>Communicate
                fast
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ImgContentSix',
};
</script>
