<template>
  <section
    class="feature-section-two ptb-120"
    :class="[bgLight ? 'bg-light' : '']"
  >
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-lg-5 col-md-12">
          <div class="section-heading" data-aos="fade-up">
            <h4 class="h5 text-primary">Features</h4>
            <h2>Powerful Solutions for Your Business Needs</h2>
            <p>
              Quickly incubate functional channels with multidisciplinary
              architectures authoritatively fabricate.
            </p>
            <ul class="list-unstyled mt-5">
              <li class="d-flex align-items-start mb-4">
                <div class="icon-box bg-primary rounded me-4">
                  <i class="fas fa-bezier-curve text-white"></i>
                </div>
                <div class="icon-content">
                  <h3 class="h5">Pixel Perfect Design</h3>
                  <p>
                    Progressively foster enterprise-wide systems whereas equity
                    invested web-readiness harness installed.
                  </p>
                </div>
              </li>
              <li class="d-flex align-items-start mb-4">
                <div class="icon-box bg-danger rounded me-4">
                  <i class="fas fa-fingerprint text-white"></i>
                </div>
                <div class="icon-content">
                  <h3 class="h5">Unique &amp; Minimal Design</h3>
                  <p>
                    Dramatically administrate progressive metrics without
                    error-free globally simplify standardized.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 col-md-7">
          <div
            class="feature-img-wrap position-relative d-flex flex-column align-items-end"
          >
            <ul class="img-overlay-list list-unstyled position-absolute">
              <li
                class="d-flex align-items-center bg-white rounded shadow-sm p-3"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <i
                  class="fas fa-check bg-primary text-white rounded-circle"
                ></i>
                <h6 class="mb-0">Create a Free Account</h6>
              </li>
              <li
                class="d-flex align-items-center bg-white rounded shadow-sm p-3"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <i
                  class="fas fa-check bg-primary text-white rounded-circle"
                ></i>
                <h6 class="mb-0">Install Our Tracking Pixel</h6>
              </li>
              <li
                class="d-flex align-items-center bg-white rounded shadow-sm p-3"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <i
                  class="fas fa-check bg-primary text-white rounded-circle"
                ></i>
                <h6 class="mb-0">Start Tracking your Website</h6>
              </li>
            </ul>
            <img
              src="@/assets/img/feature-img3.jpg"
              alt="feature image"
              class="img-fluid rounded-custom"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FeatureTwo",
  props: {
    bgLight: String,
  },
};
</script>
