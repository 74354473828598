<template>
  <section class="feature-section ptb-120">
    <div class="container">
      <div class="row align-items-lg-center justify-content-between">
        <div class="col-lg-5 order-lg-2 mb-7 mb-lg-0">
          <div class="mb-4">
            <h2>Experience your product grow business</h2>
            <p>
              Conveniently drive stand-alone total linkage for process-centric
              content. Enthusiastically administrate robust initiatives quickly
              unleash collaborative with client-focused.
            </p>
          </div>
          <ul class="list-unstyled d-flex flex-wrap list-two-col mt-5">
            <li>
              <span><i class="fal fa-user fa-2x text-primary mb-4"></i></span>
              <h3 class="h5">Customer analysis</h3>
              <p>Objectively exceptional via customized intellectual.</p>
            </li>
            <li>
              <span><i class="fal fa-watch fa-2x text-primary mb-4"></i></span>
              <h3 class="h5">Real time metrics</h3>
              <p>Interactively integrate extensible directed developer.</p>
            </li>
          </ul>
        </div>
        <div class="col-lg-6 order-lg-1">
          <div class="pr-lg-4 mt-md-4 position-relative">
            <div
              class="bg-light text-center rounded-custom overflow-hidden p-lg-5 p-4 mx-lg-auto"
            >
              <img
                src="@/assets/img/dashboard-img.png"
                alt=""
                class="img-fluid shadow-sm rounded-custom"
              />
              <div
                class="position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
