var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"game-price ptb-120"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12"},[_c('div',{staticClass:"single-game-price mb-4 mb-lg-0"},[_vm._m(1),_c('p',{staticClass:"py-3 m-0"},[_vm._v(" Whether hosting community forums, an online store, or even a blog, this platform is built to meet and surpass your every regard. ")]),_vm._m(2),_c('div',{staticClass:"game-price-btn"},[_c('router-link',{staticClass:"mt-4",attrs:{"to":"/contact"}},[_vm._v(" Check More Plans ")])],1)])]),_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12"},[_c('div',{staticClass:"single-game-price mb-4 mb-lg-0 active"},[_vm._m(3),_c('p',{staticClass:"py-3 m-0"},[_vm._v(" Whether hosting community forums, an online store, or even a blog, this platform is built to meet and surpass your every regard. ")]),_vm._m(4),_c('div',{staticClass:"game-price-btn"},[_c('router-link',{staticClass:"mt-4",attrs:{"to":"/contact"}},[_vm._v(" Check More Plans ")])],1)])]),_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12"},[_c('div',{staticClass:"single-game-price"},[_vm._m(5),_c('p',{staticClass:"py-3 m-0"},[_vm._v(" Whether hosting community forums, an online store, or even a blog, this platform is built to meet and surpass your every regard. ")]),_vm._m(6),_c('div',{staticClass:"game-price-btn"},[_c('router-link',{staticClass:"mt-4",attrs:{"to":"/contact"}},[_vm._v(" Check More Plans ")])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"section-title text-center mb-5"},[_c('h2',{staticClass:"text-white mb-4"},[_vm._v("Check Out Our Hosting Suite")]),_c('p',{staticClass:"m-0 text-off-white"},[_vm._v(" There are many variations of passages of Lorem Ipsum available, but the majority randomised words which don't look even slightly believable. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"price-icon me-3 flex-shrink-0"},[_c('img',{attrs:{"src":require("@/assets/img/wordpress.png"),"alt":"icon"}})]),_c('div',[_c('h5',{staticClass:"text-white mb-1"},[_vm._v("WordPress Hosting")]),_c('span',{staticClass:"text-off-white"},[_vm._v("Starting at $2.99/mo ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"list-unstyled"},[_c('li',{staticClass:"pb-2"},[_c('i',{staticClass:"far fa-check me-2"}),_c('span',[_vm._v("50+ Pro Widgets")])]),_c('li',{staticClass:"pb-2"},[_c('i',{staticClass:"far fa-check me-2"}),_c('span',[_vm._v("300+ Pro Templates")])]),_c('li',{staticClass:"pb-2"},[_c('i',{staticClass:"far fa-check me-2"}),_c('span',[_vm._v("Theme Builder")])]),_c('li',{staticClass:"pb-2"},[_c('i',{staticClass:"far fa-check me-2"}),_c('span',[_vm._v("Woo Commers Builder")])]),_c('li',{staticClass:"pb-2"},[_c('i',{staticClass:"far fa-check me-2"}),_c('span',[_vm._v("Support for 1 Year")])]),_c('li',{staticClass:"pb-2"},[_c('i',{staticClass:"far fa-check me-2"}),_c('span',[_vm._v("Updates for 1 Years")])]),_c('li',{staticClass:"pb-2"},[_c('i',{staticClass:"far fa-check me-2"}),_c('span',[_vm._v("WordPress Staging Tool")])]),_c('li',{staticClass:"pb-2"},[_c('i',{staticClass:"far fa-check me-2"}),_c('span',[_vm._v("Multiple Data Centers")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"price-icon me-3 flex-shrink-0"},[_c('img',{attrs:{"src":require("@/assets/img/cloud.png"),"alt":"icon"}})]),_c('div',[_c('h5',{staticClass:"text-white mb-1"},[_vm._v("Shared Hosting")]),_c('span',{staticClass:"text-off-white"},[_vm._v("Starting at $2.99/mo ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"list-unstyled"},[_c('li',{staticClass:"pb-2"},[_c('i',{staticClass:"far fa-check me-2"}),_c('span',[_vm._v("50+ Pro Widgets")])]),_c('li',{staticClass:"pb-2"},[_c('i',{staticClass:"far fa-check me-2"}),_c('span',[_vm._v("300+ Pro Templates")])]),_c('li',{staticClass:"pb-2"},[_c('i',{staticClass:"far fa-check me-2"}),_c('span',[_vm._v("Theme Builder")])]),_c('li',{staticClass:"pb-2"},[_c('i',{staticClass:"far fa-check me-2"}),_c('span',[_vm._v("Woo Commers Builder")])]),_c('li',{staticClass:"pb-2"},[_c('i',{staticClass:"far fa-check me-2"}),_c('span',[_vm._v("Support for 1 Year")])]),_c('li',{staticClass:"pb-2"},[_c('i',{staticClass:"far fa-check me-2"}),_c('span',[_vm._v("Updates for 1 Years")])]),_c('li',{staticClass:"pb-2"},[_c('i',{staticClass:"far fa-check me-2"}),_c('span',[_vm._v("WordPress Staging Tool")])]),_c('li',{staticClass:"pb-2"},[_c('i',{staticClass:"far fa-check me-2"}),_c('span',[_vm._v("Multiple Data Centers")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"price-icon me-3 flex-shrink-0"},[_c('img',{attrs:{"src":require("@/assets/img/globe.png"),"alt":"icon"}})]),_c('div',[_c('h5',{staticClass:"text-white mb-1"},[_vm._v("VPS Hosting")]),_c('span',{staticClass:"text-off-white"},[_vm._v("Starting at $2.99/mo ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"list-unstyled"},[_c('li',{staticClass:"pb-2"},[_c('i',{staticClass:"far fa-check me-2"}),_c('span',[_vm._v("50+ Pro Widgets")])]),_c('li',{staticClass:"pb-2"},[_c('i',{staticClass:"far fa-check me-2"}),_c('span',[_vm._v("300+ Pro Templates")])]),_c('li',{staticClass:"pb-2"},[_c('i',{staticClass:"far fa-check me-2"}),_c('span',[_vm._v("Theme Builder")])]),_c('li',{staticClass:"pb-2"},[_c('i',{staticClass:"far fa-check me-2"}),_c('span',[_vm._v("Woo Commers Builder")])]),_c('li',{staticClass:"pb-2"},[_c('i',{staticClass:"far fa-check me-2"}),_c('span',[_vm._v("Support for 1 Year")])]),_c('li',{staticClass:"pb-2"},[_c('i',{staticClass:"far fa-check me-2"}),_c('span',[_vm._v("Updates for 1 Years")])]),_c('li',{staticClass:"pb-2"},[_c('i',{staticClass:"far fa-check me-2"}),_c('span',[_vm._v("WordPress Staging Tool")])]),_c('li',{staticClass:"pb-2"},[_c('i',{staticClass:"far fa-check me-2"}),_c('span',[_vm._v("Multiple Data Centers")])])])
}]

export { render, staticRenderFns }