<template>
  <section class="customer-review pb-120 bg-dark">
    <div class="container">
      <div class="row">
        <div class="section-heading text-center" data-aos="fade-up">
          <h2 class="fw-medium h4">Rated 5 out of 5 stars by our customers</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-4">
          <div
            class="review-info-wrap text-center rounded-custom d-flex flex-column h-100 p-5"
            data-aos="fade-up"
            data-aos-delay="50"
          >
            <img
              src="@/assets/img/fb-logo-w.svg"
              width="130"
              alt="facebook"
              class="img-fluid m-auto"
            />
            <div class="review-info-content mt-2">
              <p class="mb-4">
                4.9 out of 5 stars maintainable disseminate parallel team
                effective standards communities.
              </p>
            </div>
            <router-link
              to="/about-us"
              class="link-with-icon p-0 mt-auto text-decoration-none text-warning"
              >Read Reviews <i class="far fa-arrow-right"></i
            ></router-link>
          </div>
        </div>
        <div class="col-lg-4 col-md-4">
          <div
            class="review-info-wrap text-center rounded-custom d-flex flex-column h-100 p-5"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <img
              src="@/assets/img/g-logo-w.svg"
              width="130"
              alt="google"
              class="img-fluid m-auto"
            />
            <div class="review-info-content mt-2">
              <p class="mb-4">
                2k+ five star reviews excellent convergence without professional
                methods of empowerment.
              </p>
            </div>
            <router-link
              to="/about-us"
              class="link-with-icon p-0 mt-auto text-decoration-none text-warning"
              >Read Reviews <i class="far fa-arrow-right"></i
            ></router-link>
          </div>
        </div>
        <div class="col-lg-4 col-md-4">
          <div
            class="review-info-wrap text-center rounded-custom d-flex flex-column h-100 p-5"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            <img
              src="@/assets/img/li-logo-w.svg"
              width="130"
              alt="linkedIn"
              class="img-fluid m-auto"
            />
            <div class="review-info-content mt-2">
              <p class="mb-4">
                4.9 out of 5 stars maintainable disseminate parallel team
                effective standards communities.
              </p>
            </div>
            <router-link
              to="/about-us"
              class="link-with-icon p-0 mt-auto text-decoration-none text-warning"
              >Read Reviews <i class="far fa-arrow-right"></i
            ></router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Promo",
};
</script>
