<template>
  <section class="process-section bg-light ptb-120">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-12">
          <div class="section-heading text-center" data-aos="fade-up">
            <h4 class="h5 text-primary">Instant Setup</h4>
            <h2>Simple & Effortless Process</h2>
            <p>
              Phosfluorescently enabled growth strategies after worldwide
              proactive partnerships cooperative convergence via orthogonal
              experiences.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="setup-process-wrap">
            <ul class="setup-process-list list-unstyled text-center mb-0">
              <li class="setup-process-item py-4 px-2" data-aos="fade-up">
                <div class="setup-process-icon mb-4">
                  <i class="fal fa-download fa-2x text-primary"></i>
                </div>
                <span
                  class="process-tag badge bg-primary-soft py-1 px-3 small fw-bold text-primary rounded-custom"
                  >Step 1</span
                >
                <h6 class="mt-3 mb-0">Download the app</h6>
              </li>
              <li
                class="setup-process-item py-4 px-2"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div class="setup-process-icon mb-4">
                  <i class="fal fa-street-view fa-2x text-primary"></i>
                </div>
                <span
                  class="process-tag badge bg-primary-soft py-1 px-3 small fw-bold text-primary rounded-custom"
                  >Step 2</span
                >
                <h6 class="mt-3 mb-0">Invite teammates</h6>
              </li>
              <li
                class="setup-process-item py-4 px-2"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div class="setup-process-icon mb-4">
                  <i class="fal fa-bezier-curve fa-2x text-primary"></i>
                </div>
                <span
                  class="process-tag badge bg-primary-soft py-1 px-3 small fw-bold text-primary rounded-custom"
                  >Step 3</span
                >
                <h6 class="mt-3 mb-0">Create workspace</h6>
              </li>
              <li
                class="setup-process-item py-4 px-2"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <div class="setup-process-icon mb-4">
                  <i class="fal fa-user-shield fa-2x text-primary"></i>
                </div>
                <span
                  class="process-tag badge bg-primary-soft py-1 px-3 small fw-bold text-primary rounded-custom"
                  >Step 4</span
                >
                <h6 class="mt-3 mb-0">Track performance</h6>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WorkProcessThree',
};
</script>
