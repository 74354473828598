<template>
  <footer class="footer-section">
    <!--footer bottom start-->
    <div class="footer-bottom bg-dark text-white py-4">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-7 col-lg-7">
            <div class="copyright-text">
              <p class="mb-lg-0 mb-md-0">
                &copy; 2022 Quiety Rights Reserved. Designed By
                <a href="https://themetags.com/" class="text-decoration-none"
                  >ThemeTags</a
                >
              </p>
            </div>
          </div>
          <div class="col-md-4 col-lg-4">
            <div class="footer-single-col text-start text-lg-end text-md-end">
              <ul class="list-unstyled list-inline footer-social-list mb-0">
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-instagram"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-dribbble"></i></a>
                </li>
                <li class="list-inline-item">
                  <a href="#"><i class="fab fa-github"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--footer bottom end-->
  </footer>
</template>

<script>
export default {};
</script>
